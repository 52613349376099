import { AllowCatalogs, ExtraSettings, ExtraSettingsValue } from "../../models/queueValues"

const getDefaultTimeObject = () => ({ Days: 0, Hours: 0, Minutes: 0, Seconds: 0 })

export const EXTRA_SETTINGS_DEFAULT_VALUES: Exclude<ExtraSettingsValue, AllowCatalogs>[] = [
    {
        type: ExtraSettings.DialogTimer,
        value: {
            greenTimer: getDefaultTimeObject(),
            yellowTimer: getDefaultTimeObject()
        }
    },
    {
        type: ExtraSettings.DialogFinish,
        value: { finishDialogArticle: "" }
    },
    {
        type: ExtraSettings.AutoGreeting,
        value: {
            operatorAutoGreetingEnabled: false,
            operatorAfterHoldGreetingEnabled: false,
            template: ""
        }
    },
    {
        type: ExtraSettings.ClientSurvey,
        value: { mainSurveyId: "", shortSurveyId: "" }
    },
    {
        type: ExtraSettings.AdditionalResendConfig,
        value: { enable: false, template: "" }
    },
    {
        type: ExtraSettings.EmailChannels,
        value: { defaultChannelId: "", additionalChannelsIds: [] }
    },
    {
        type: ExtraSettings.FastReplyTemplates,
        value: { catalogId: "" }
    },
    {
        type: ExtraSettings.AutoHolding,
        value: {
            enabled: false,
            triggerMessages: [],
            messagesToSend: [],
            timeout: getDefaultTimeObject(),
            multipleSending: false
        }
    },
    {
        type: ExtraSettings.DialogGroups,
        value: {
            groups: []
        }
    }
]

export const extraSettingsDefaultOptions = [
    ExtraSettings.DialogTimer,
    ExtraSettings.DialogFinish,
    ExtraSettings.AutoGreeting,
    ExtraSettings.ClientSurvey,
    ExtraSettings.AdditionalResendConfig,
    ExtraSettings.EmailChannels,
    ExtraSettings.FastReplyTemplates,
    ExtraSettings.AutoHolding,
    ExtraSettings.AllowCatalogs,
    ExtraSettings.DialogGroups
]
