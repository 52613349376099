import { WithTitle } from "./withTitle"
import { useCallback, useState } from "react"

export interface SidebarContentBase extends WithTitle {
    stepBack?: boolean
    onBack?: () => void
    onCloseSidebar?: () => void
    extended?: boolean
    settings?: JSX.Element[]
    action?: JSX.Element
}

export default function useSidebar<T extends SidebarContentBase>() {
    const [sidebarClosed, setSidebarClosed] = useState(true)
    const [sidebarContent, setSidebarContent] = useState<T | null>(null)

    const openSidebar = useCallback(() => setSidebarClosed(false), [])
    const closeSidebar = useCallback(() => {
        setSidebarClosed(true)
    }, [])

    const handleChangeSidebarContent = useCallback(
        (content: T) => {
            closeSidebar()
            setSidebarContent(content)
            openSidebar()
        },
        [closeSidebar, openSidebar]
    )

    const handleSetSidebarContent = useCallback((content: T) => {
        setSidebarContent(content)
    }, [])

    return {
        handleSetSidebarContent,
        handleChangeSidebarContent,
        openSidebar,
        closeSidebar,
        sidebarContent,
        sidebarClosed
    }
}
