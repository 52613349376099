import * as linkify from "linkifyjs"

export function formatLinksInMarkdown(text: string) {
    const links = linkify.find(text)

    links.reverse().forEach(link => {
        const { href, start, end, value } = link

        const markdownLinkPattern = /\[(.*?)\]\((.*?)\)/g

        let isAlreadyFormatted = false
        let match
        while ((match = markdownLinkPattern.exec(text)) !== null) {
            const matchStart = match.index
            const matchEnd = markdownLinkPattern.lastIndex
            if (start >= matchStart && end <= matchEnd) {
                isAlreadyFormatted = true
                break
            }
        }

        if (!isAlreadyFormatted) {
            const markdownLink = `[${value}](${href})`

            let offset = 0

            if (text.includes(`<${href}>`, start - 1)) offset = 1

            text = text.slice(0, start - offset) + markdownLink + text.slice(end + offset)
        }
    })

    return text
}
