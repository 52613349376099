import { Scenario } from "./scenario"
import { KnowledgeBaseDefaultEntity } from "./knowledgeBase/defaultEntity"
import { KnowledgeBasePermission } from "./knowledgeBase/permission"
import { ArticleUserView } from "./articleUserViews"
import { CategoryMutation } from "./category"
import { NewslineSettings } from "./knowledgeBase/newsline"
import {
    ArticleParameterComparison,
    Comparison,
    ComparisonType,
    QuestionAndAnswersComparisonResult
} from "./articleCompare"

export enum ArticleStatusType {
    Active = "Active",
    Inactive = "Inactive",
    Removed = "Removed",
    Draft = "Draft",
    Approval = "Approval",
    None = "None"
}

export enum ArticleAction {
    View = "view",
    Edit = "edit"
}

export enum ArticleKind {
    Common = "Common",
    Scenario = "Scenario",
    External = "External"
}

export interface ArticleAnswerSlot {
    SlotId: string
    Values: string[]
}

export interface ArticleAnswer {
    Id: string
    Text: string
    Slots: ArticleAnswerSlot[]
}

export interface ArticleCategory {
    CatalogCode: string
    SymbolCode: string
    SearchValue: string
}

export interface ArticleParameter {
    SlotId: string
    Value: string
    Comparison?: ArticleParameterComparison
}

export interface ArticleQuestion {
    Sentence: string
    ComparisonType?: ComparisonType
}

export type ArticleTag = string

export interface WithVersionId {
    Id: string
}

export interface Article extends KnowledgeBaseDefaultEntity, WithVersionId {
    Status: ArticleStatusType
    Categories?: ArticleCategory[]
    Questions?: ArticleQuestion[]
    Answers?: ArticleAnswer[]
    Survey?: string[]
    Scenario?: Scenario

    Expire: number
    PrevVersion: number
    FirstVersion: number

    ModifiedDate: number
    ModifiedUserLogin: string

    Type: string
    Kind: ArticleKind
    Tags: ArticleTag[]
    Parameters: ArticleParameter[]
    Permissions: KnowledgeBasePermission[]
    PermissionsWithNested: boolean
    Expanded: boolean
    NewslineSettigsDTO?: NewslineSettings
    NewslineId?: string
    TitleComparison?: Comparison<string>[]
    QuestionsComparisonResult?: QuestionAndAnswersComparisonResult
}

export interface PermissionsArticleUpdated {
    SymbolCode: string
    Status: ArticleStatusType
    Permissions: KnowledgeBasePermission[]
    NewArticleId: string
    PermissionsWithNested: boolean
}

export interface ArticleView extends KnowledgeBaseDefaultEntity {
    Answers?: ArticleAnswer[]
    Scenario?: Scenario
    ModifiedDate: number
}

export interface ArticleEditFormValues {
    Title: string
    Kind: ArticleKind
    Type: string
    Tags: string
    Parameters: ArticleParameterValue[]
    Expanded: boolean
}

export enum EArticleEditFormChangesInheritance {
    ROOT = "ROOT",
    INHERIT = "INHERIT"
}

export interface ArticleParameterValue extends ArticleParameter {
    Id: string
}

export interface ArticleShareFormValues {
    ShareLink: string
    Permissions: KnowledgeBasePermission[]
    ChangesInheritance: EArticleEditFormChangesInheritance
}

export interface UpdateArticleRequest extends WithVersionId {
    Title?: string
    Answers?: ArticleAnswer[]
    Type?: string
    Tags?: ArticleTag[]
    Parameters?: ArticleParameter[]
    Questions?: ArticleQuestion[]
    ExtId?: string
    Survey?: string[]
    Expanded: boolean
}

export interface UpdateActualArticlePermissionsRequest extends WithVersionId {
    Permissions: KnowledgeBasePermission[]
    WithNested: boolean
}

export interface UpdateScenarioRequest extends WithVersionId {
    Title?: string
    Type?: string
    Tags?: ArticleTag[]
    Scenario: Scenario
    Questions?: ArticleQuestion[]
    ExtId?: string
    Survey?: string[]
}

export interface PublishArticleResponse {
    Article: Article
    ArticleUserViews: ArticleUserView[]
}

export interface SearchArticlesRequest {
    Text?: string
    Type?: string
    Tags?: string[]
    ParameterValues?: string[]
    DateFrom?: number
    DateTo?: number
    EnableRemoved?: boolean
    EnablePayload?: boolean
    SearchBySymbolCode?: boolean
    AllowWithoutPermissions?: boolean
    PageFrom?: number
    PageSize?: number
    ExtId?: string
    ExtSourceId?: string
}

export type SearchArticleHighlights = { [field: string]: string[] }
export type SearchArticlesHighlights = {
    [articleId: string]: SearchArticleHighlights
}
export type SearchArticlesScores = { [articleId: string]: number }

export interface SearchArticlesResponse {
    Articles: ArticleView[]
    Highlights?: SearchArticlesHighlights
    Scores?: SearchArticlesScores
    Total: number
}

export interface ShareArticleResponse {
    ArticleSymbolCode: string
    SharedUrl: string
}

export interface ArticleAnswersFormValues {
    Answers: ArticleAnswer[]
}

export interface ChooseArticleFormValues {
    SymbolCode: string
}

export interface GetMarkedMessagesResponse {
    data: MarkedMessage[]
    draw: number
    recordsFiltered: number
    recordsTotal: number
}

export interface MarkedMessage {
    id: string
    text: string
}

export interface MarkedMessagesReserve {
    Questions: SampleQuestionType[]
    Count: number
    RemovedIds: string[]
}

export enum QuestionType {
    All = "all",
    Manual = "manual",
    Operator = "operator"
}

export interface SampleQuestionType extends MarkedMessage {
    type: QuestionType
    comparisonType?: ComparisonType
    previousCount?: number
}

export interface GetMessageMarkupRequest {
    start: number
    length: number
    search: GetMessageMarkupRequestSearch
}

interface GetMessageMarkupRequestSearch {
    value: string
    regex: boolean
}

export interface GetArticleResponse {
    Article?: Article
    Views: ArticleUserView[]
    Mutations: CategoryMutation[]
    IsLocked: boolean
}

export interface GetAllPublishActualArticlesInCatalogRequest {
    CatalogCode: string
    Login: string
    RoleId?: string
}

export interface GetAllPublishArticlesResponse {
    Articles: Article[]
}

export interface GetActualArticleFrontendResponse {
    Article: Article
    Mutations?: CategoryMutation[]
}

export interface ArticleTitle {
    SymbolCode: string
    Id: string
    Title: string
}

export interface GetPublishArticleTitlesRequest {
    SymbolCodes?: string[]
    Login: string
    RoleId?: string
}

export interface GetPublishArticleTitlesResponse {
    ArticleTitles: ArticleTitle[]
}
