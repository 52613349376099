import React from "react"
import { FormControlProps } from "react-bootstrap/FormControl"
import { FormValue } from "../../types/FormValue"
import { useTranslation } from "react-i18next"
import { useField } from "formik"
import { Form } from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import cn from "classnames"
import { faExclamationCircle, faCaretDown } from "@fortawesome/pro-light-svg-icons"

export interface BaseValidatableInputProps extends FormControlProps {
    id?: string
    name: string
    label?: string
    placeholder?: string
    rows?: string
    cols?: string
    icon?: JSX.Element
    autocomplete?: "on" | "off" | string
    testId?: string
    children?: React.ReactNode
    className?: string
}

function ValidatableInputBase<Value extends FormValue>(props: BaseValidatableInputProps) {
    const { rows, cols, icon, testId, label, children, autocomplete = "on", name, ...inputProps } = props
    const { t } = useTranslation()
    const [field, meta] = useField<Value>(name)
    const isSelect = inputProps.as === "select"

    return (
        <div className="validatable-input__wrap">
            <Form.Control
                {...inputProps}
                {...field}
                className={cn(isSelect && "select-input")}
                rows={rows}
                cols={cols}
                isInvalid={meta.touched && !!meta.error}
                autoComplete={autocomplete}
                data-test-id={testId}
            >
                {children}
            </Form.Control>
            <Form.Control.Feedback type="invalid">{meta.error && meta.touched && t(meta.error)}</Form.Control.Feedback>
            {isSelect && (
                <div
                    className={cn(
                        "validatable-input__icon",
                        "validatable-input__icon_arrow",
                        meta.error && meta.touched && "validatable-input__icon_error"
                    )}
                >
                    <FontAwesomeIcon icon={faCaretDown} />
                </div>
            )}
            {isSelect && meta.error && meta.touched && (
                <div className="validatable-input__icon validatable-input__icon_error validatable-input__icon_alert">
                    <FontAwesomeIcon icon={faExclamationCircle} />
                </div>
            )}
        </div>
    )
}

export default ValidatableInputBase
