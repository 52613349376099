import React, { useState } from "react"
import styles from "./ProjectSelector.module.scss"
import { Project } from "../../models/project"
import ContentLoader from "react-content-loader"
import cn from "classnames"
import { WithT } from "i18next"

import ProjectCard from "../ProjectCard/ProjectCard"
import WizardRedirectButton from "../WizardRedirectButton/WizardRedirectButton"
import SidebarContextMenu from "../SidebarContextMenu/SidebarContextMenu"
import { Supervisor } from "../../permissions"
import Can from "../Can/Can"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSearch } from "@fortawesome/pro-light-svg-icons/faSearch"
import { FormControl } from "react-bootstrap"
import { formTranslation } from "../../locales/form"
import { useTranslation } from "react-i18next"
import CopyWizardRedirectButton from "../WizardRedirectButton/CopyWizardRedirectButton"
import { testId } from "../../utility/tests/testId"
import { useOpenedState } from "../../utility/common/useOpenedState"
import Spinner from "../Spinner/Spinner"
import { faAngleDown } from "@fortawesome/pro-light-svg-icons/faAngleDown"

const WIDE_NAME_CHARS_COUNT = 14

export interface ProjectSelectorProps {
    selectedProject: Project
    nonSelectedProject: Project[]
    selectProject: (projectId: string) => void
    inHeader?: boolean
}

const ProjectSelector: React.FC<ProjectSelectorProps> = props => {
    const { t } = useTranslation()
    const { selectedProject, nonSelectedProject, inHeader } = props
    const [searchInput, setSearchInput] = useState("")
    const [opened, toggleOpen] = useOpenedState(false)

    const selectProject = async (project: Project) => {
        await props.selectProject(project.id)
    }
    const isWideName = selectedProject.name.length >= WIDE_NAME_CHARS_COUNT

    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchInput(e.target.value)
    }
    const filteredNonSelectedProjects = searchInput
        ? nonSelectedProject.filter(p => p.name.toLowerCase().includes(searchInput.toLowerCase()))
        : nonSelectedProject

    return (
        <SidebarContextMenu
            placement={`bottom-${inHeader ? "start" : "end"}`}
            containerClassName={cn(
                styles.projectSelector,
                inHeader ? styles.projectSelector_header : styles.projectSelector_sidebar
            )}
            items={
                <div className={styles.projectSelector__contextMenu}>
                    {nonSelectedProject.length > 10 && (
                        <div className={styles.projectSelector__search} onClick={e => e.stopPropagation()}>
                            <FontAwesomeIcon icon={faSearch} className={styles.projectSelector__searchIcon} />
                            <FormControl
                                onChange={handleSearchChange}
                                value={searchInput}
                                className={styles.projectSelector__searchInput}
                                placeholder={t(formTranslation.search)}
                                data-test-id={testId.searchProject}
                            />
                        </div>
                    )}
                    <div className={styles.projectSelector__projects}>
                        {filteredNonSelectedProjects.map(project => (
                            <ProjectCard
                                key={project.id}
                                project={project}
                                onClick={() => selectProject(project)}
                                className={cn(styles.projectSelector__project, styles.projectSelector__dropdownProject)}
                            />
                        ))}
                    </div>
                    {!!filteredNonSelectedProjects.length && <div className={styles.projectSelector__divider} />}
                    <Can permission={Supervisor}>
                        <WizardRedirectButton className={styles.projectSelector__wizardRedirect} />
                    </Can>
                    <Can permission={Supervisor}>
                        <CopyWizardRedirectButton className={styles.projectSelector__wizardRedirect} />
                    </Can>
                </div>
            }
        >
            <button
                className={styles.projectSelector__selectedContainer}
                data-test-id={testId.projectSelector}
                onClick={toggleOpen}
            >
                <ProjectCard
                    project={selectedProject}
                    className={cn(
                        styles.projectSelector__selected,
                        isWideName && styles.projectSelector__selected_wideName
                    )}
                    isShort={!inHeader}
                />
            </button>
            {inHeader && <FontAwesomeIcon icon={faAngleDown} rotation={opened ? 180 : undefined} size="lg" />}
        </SidebarContextMenu>
    )
}

interface LoadingProps extends WithT {
    inHeader?: boolean
}

export const ProjectSelectorLoading: React.FC<LoadingProps> = ({ inHeader }) => {
    return inHeader ? (
        <Spinner className={styles.projectSelector__spinner} />
    ) : (
        <ContentLoader height={56} title={""} className={styles.projectSelector}>
            <rect x="0" y="0" rx="3" ry="3" width="79" height="56" />
        </ContentLoader>
    )
}

export default ProjectSelector
