import React, { useCallback, useEffect, useState } from "react"
import styles from "./QueueAutoGreeting.module.scss"
import Toggle from "../../../../Toggle/Toggle"
import DebouncedValidatableInput from "../../../../ValidatableInput/DebouncedValidatableInput"
import { AutoGreetingValues, QueueValues } from "../../../../../models/queueValues"
import { WithT } from "i18next"
import { useFormikContext } from "formik"
import { testId } from "../../../../../utility/tests/testId"

export interface QueueAutoGreetingProps extends WithT {
    settingValue: AutoGreetingValues
    templateName: string
    index: number
}

const tNamespace = "queues:settings."

const OPERATOR_AUTOGREETING_ENABLED = "operatorAutoGreetingEnabled"
const OPERATOR_AFTER_HOLD_GREETING_ENABLED = "operatorAfterHoldGreetingEnabled"

const QueueAutoGreeting: React.FC<QueueAutoGreetingProps> = props => {
    const { t, settingValue, templateName, index } = props
    const [isFirstTimeEnabled, setIsFirstTimeEnabled] = useState<boolean>(false)
    const [isAfterHold, setIsAfterHold] = useState<boolean>(false)
    const { setFieldValue } = useFormikContext<QueueValues>()

    useEffect(() => {
        setIsFirstTimeEnabled(settingValue.operatorAutoGreetingEnabled)
        setIsAfterHold(settingValue.operatorAfterHoldGreetingEnabled)
    }, [settingValue])

    const handleToggleChange = useCallback(
        (field: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
            const checked = e.target.checked

            switch (field) {
                case OPERATOR_AUTOGREETING_ENABLED:
                    setIsFirstTimeEnabled(checked)
                    break
                case OPERATOR_AFTER_HOLD_GREETING_ENABLED:
                    setIsAfterHold(checked)
                    break
            }

            setFieldValue(`extraSettings[${index}].value.${field}`, checked, false)
        },
        [index, setFieldValue]
    )

    return (
        <>
            <div className={styles.queueAutoGreeting__toggle} data-test-id={testId.queueAutoGreetingToggle}>
                <Toggle
                    id="isFirstDialogInQueueEnabled"
                    icons={false}
                    checked={settingValue.operatorAutoGreetingEnabled}
                    onChange={handleToggleChange(OPERATOR_AUTOGREETING_ENABLED)}
                />
                <label htmlFor="isFirstDialogInQueueEnabled" className={styles.queueAutoGreeting__toggle__label}>
                    {t(`${tNamespace}first-time-dialog`)}
                </label>
            </div>
            <div className={styles.queueAutoGreeting__toggle} data-test-id={testId.queueAutoGreetingHoldToggle}>
                <Toggle
                    id="isDialogFromHold"
                    icons={false}
                    checked={settingValue.operatorAfterHoldGreetingEnabled}
                    onChange={handleToggleChange(OPERATOR_AFTER_HOLD_GREETING_ENABLED)}
                />
                <label htmlFor="isDialogFromHold" className={styles.queueAutoGreeting__toggle__label}>
                    {t(`${tNamespace}from-hold-dialog`)}
                </label>
            </div>
            <DebouncedValidatableInput
                id="formAutoGreetingTemplate"
                as="textarea"
                name={templateName}
                label={t(`${tNamespace}autogreeting-template`)}
                className={styles.queueAutoGreeting__textarea}
                data-test-id={testId.queueAutoGreetingTemplate}
            />
        </>
    )
}

export default QueueAutoGreeting
