import React, { useContext } from "react"
import { useSelector } from "react-redux"
import { selectCatalogsState } from "../../store/knowledgeBase/selectors"
import ErrorMessage from "../ErrorMessage/ErrorMessage"
import Async from "../Async/Async"
import CatalogsLoader from "./CatalogsLoader"
import KnowledgeBaseContext from "../KnowledgeBaseProvider/KnowledgeBaseContext"
import EditableCatalog, { EditableCatalogProps } from "../Catalog/EditableCatalog/EditableCatalog"
import ErrorBoundary from "../ErrorBoundary/ErrorBoundary"
import { ItemId } from "@atlaskit/tree/types"
import { ArticleStatusType } from "../../models/article"

export enum CatalogPlacement {
    knowledgeBase,
    workplace,
    massMailing
}

interface CatalogsProps extends Omit<EditableCatalogProps, "projectId" | "id" | "url" | "canModifyKnowledgeBase"> {
    allowedCatalogs?: ItemId[]
}

const Catalogs: React.FC<CatalogsProps> = props => {
    const { allowedCatalogs, ...catalogProps } = props

    const apiState = useSelector(selectCatalogsState)
    const { url, canModifyKnowledgeBase, onOpenSharingCatalog, projectId } = useContext(KnowledgeBaseContext)
    const isKnowledgeBase = props.catalogPlacement === CatalogPlacement.knowledgeBase

    return (
        <Async
            dataState={apiState}
            processView={<CatalogsLoader itemsCount={3} />}
            errorView={({ message }) => <ErrorMessage text={message} />}
        >
            {({ data: catalogIds }) => {
                const allowedCatalogIds = allowedCatalogs
                    ? catalogIds.filter(id => allowedCatalogs.includes(id))
                    : catalogIds

                return (
                    <ErrorBoundary local>
                        {projectId &&
                            allowedCatalogIds.map(id => (
                                <EditableCatalog
                                    key={`${id}-editable-catalog`}
                                    projectId={projectId}
                                    id={String(id)}
                                    url={url}
                                    canModifyKnowledgeBase={canModifyKnowledgeBase}
                                    onOpenSharingCatalog={onOpenSharingCatalog}
                                    {...catalogProps}
                                    isDragEnabled={isKnowledgeBase}
                                    availableStatuses={!isKnowledgeBase ? [ArticleStatusType.Active] : undefined}
                                />
                            ))}
                    </ErrorBoundary>
                )
            }}
        </Async>
    )
}

export default Catalogs
