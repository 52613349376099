import React from "react"
import "./UserAccessForm.scss"
import { FormikProps, FormikValues } from "formik/dist/types"
import UserAccess, { UserAccessCommonProps, UserAssessItemType } from "../UserAccess/UserAccess"
import {
    UserAccessDifference,
    UserAccessDifferenceAction,
    UserAccessPrimitive
} from "../../models/knowledgeBase/userAccess"
import { getUserAccessString } from "../../utility/knowledgeBase/knowledgeBase"

interface Props extends UserAccessCommonProps {
    type: UserAssessItemType
    title: string
    name: string
    diffFieldName?: string
    rolesPlaceholder?: string
}

function UserAccessForm<Result extends FormikValues>(props: Props & FormikProps<Result>) {
    const {
        type,
        title,
        subscriptionOptions,
        permittedActionOptions,
        name,
        currentProject,
        projects,
        diffFieldName,
        values,
        setFieldValue,
        rolesPlaceholder,
        disableProjectsSelector
    } = props

    const addDiffValue = (action: UserAccessDifferenceAction) => (value: UserAccessPrimitive) => {
        if (!diffFieldName) {
            return
        }
        const addedValues = values[diffFieldName] as UserAccessDifference
        const valueAsString = getUserAccessString(value)
        addedValues[valueAsString] = { value, action }
        setFieldValue(diffFieldName, addedValues, true)
    }

    return (
        <UserAccess
            type={type}
            name={name}
            title={title}
            subscriptionOptions={subscriptionOptions}
            permittedActionOptions={permittedActionOptions}
            currentProject={currentProject}
            projects={projects}
            rolesPlaceholder={rolesPlaceholder}
            onAddToAddedList={addDiffValue("added")}
            onAddToRemovedList={addDiffValue("removed")}
            disableProjectsSelector={disableProjectsSelector}
        />
    )
}

export default UserAccessForm
