import { useEffect, useState } from "react"

export const useIsTabActive = () => {
    const [isTabActive, setIsTabActive] = useState(true)

    useEffect(() => {
        const handleVisibilityChange = () => {
            setIsTabActive(!document.hidden)
        }

        document.addEventListener("visibilitychange", handleVisibilityChange)

        return () => {
            document.removeEventListener("visibilitychange", handleVisibilityChange)
        }
    }, [])

    return isTabActive
}
