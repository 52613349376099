import { Queue } from "../../models/queue"
import { TaskCommon, TaskOfAutomaticProcessing } from "../../models/task"
import { Operator } from "../../models/operator"
import useSidebar, { SidebarContentBase } from "../../utility/common/useSidebar"
import React, { useCallback, useState } from "react"
import SettingsContextMenuItem from "../../components/SettingsContextMenuItem/SettingsContextMenuItem"
import { faTrash } from "@fortawesome/pro-light-svg-icons/faTrash"
import { formTranslation } from "../../locales/form"
import { deleteQueue, getTaskMessages } from "../../store/queues/thunks"
import { Project } from "../../models/project"
import { ChannelNames } from "../../models/channel"
import FormikQueueForm from "../../components/QueueForm/QueueForm"
import TaskView from "../../components/TaskView/TaskView"
import OperatorView from "../../components/OperatorView/OperatorView"
import { ItemId } from "@atlaskit/tree/types"
import { useModal } from "../../utility/common/useModal"
import AlertDialog from "../../components/AlertDialog/AlertDialog"
import { logError } from "../../utility/common/logError"
import { Dispatch } from "../../utility/common/storeHelper"
import IconControl from "../../components/IconControl/IconControl"
import { faSync } from "@fortawesome/pro-light-svg-icons"
import { useTranslation } from "react-i18next"

const queueAddForm = "queueAddForm"
interface QueueAddFormContent extends SidebarContentBase {
    type: typeof queueAddForm
    categoryId: ItemId
}

const queueUpdateForm = "queueUpdateForm"
interface QueueUpdateFormContent extends SidebarContentBase {
    type: typeof queueUpdateForm
    queue: Queue
}

const taskView = "taskView"
interface TaskViewContent extends SidebarContentBase {
    type: typeof taskView
    task: TaskCommon
    operator?: Operator
    queue?: Queue
}

const operatorView = "operatorView"
interface OperatorViewContent extends SidebarContentBase {
    type: typeof operatorView
    operator: Operator
}

const taskOfAutomaticProcessingView = "taskOfAutomaticProcessingView"
interface TaskOfAutomaticProcessingContent extends SidebarContentBase {
    type: typeof taskOfAutomaticProcessingView
    task: TaskOfAutomaticProcessing
}

const tNamespace = "queues:"

export type QueuesSidebarContent =
    | QueueAddFormContent
    | QueueUpdateFormContent
    | TaskViewContent
    | OperatorViewContent
    | TaskOfAutomaticProcessingContent

export default function useQueuesSidebar(dispatch: Dispatch, allQueues: Queue[], project?: Project) {
    const { t } = useTranslation()
    const { handleChangeSidebarContent, closeSidebar, sidebarContent, sidebarClosed } =
        useSidebar<QueuesSidebarContent>()

    const { modalOpen, openModal, closeModal, onExited } = useModal(() => (
        <AlertDialog
            show={modalOpen}
            title={t(`${tNamespace}delete-confirmation.title`)}
            message={t(`${tNamespace}delete-confirmation.message`)}
            submitBtnText={t(formTranslation.delete)}
            onClose={closeModal}
            onSubmit={handleDeleteQueue}
            variant="danger"
            onExited={onExited}
        />
    ))

    const [queueToDelete, setQueueToDelete] = useState<string | null>(null)
    const [selectedCategoryId, setSelectedCategoryId] = useState<string | null>(null)

    const onCloseSidebar = useCallback(() => {
        closeSidebar()
    }, [closeSidebar])

    const handleDeleteQueue = useCallback(() => {
        if (queueToDelete && selectedCategoryId && project) {
            dispatch(deleteQueue(project.id, selectedCategoryId, queueToDelete, closeSidebar))
        } else {
            logError(
                "Failed to delete queue. Necessary params are not found.",
                project,
                queueToDelete,
                selectedCategoryId
            )
        }
    }, [queueToDelete, selectedCategoryId, project, dispatch, closeSidebar])

    const handleAddQueue = useCallback(
        (categoryId: ItemId) => {
            handleChangeSidebarContent({
                type: queueAddForm,
                categoryId,
                title: t(`${tNamespace}adding-queue`)
            })
        },
        [handleChangeSidebarContent, t]
    )

    const handleUpdateQueue = useCallback(
        (queue: Queue) => {
            const settings = [
                <SettingsContextMenuItem
                    key={queue.Id}
                    id={queue.Id}
                    icon={faTrash}
                    text={t(formTranslation.delete)}
                    danger
                    onClick={() => {
                        setSelectedCategoryId(queue.CategoryId)
                        setQueueToDelete(queue.Id)
                        openModal()
                    }}
                />
            ]

            handleChangeSidebarContent({
                type: queueUpdateForm,
                title: t(`${tNamespace}editing-queue`),
                queue,
                settings
            })
        },
        [handleChangeSidebarContent, openModal, t]
    )

    const handleOperatorView = useCallback(
        (operator: Operator) => {
            handleChangeSidebarContent({
                type: operatorView,
                title: t(`${tNamespace}operator-view`),
                operator
            })
        },
        [handleChangeSidebarContent, t]
    )

    const handleAssignedTaskView = useCallback(
        (task: TaskCommon, operator: Operator) => {
            handleChangeSidebarContent({
                type: taskView,
                title: t(`${tNamespace}task-view`),
                operator,
                task,
                onBack: () => handleOperatorView(operator),
                action: (
                    <IconControl
                        icon={faSync}
                        size="1x"
                        onClick={() => {
                            dispatch(getTaskMessages(task.Id))
                        }}
                    />
                )
            })
        },
        [handleChangeSidebarContent, handleOperatorView, t]
    )

    const handleTaskOfAutomaticProcessingView = useCallback(
        (task: TaskOfAutomaticProcessing) => {
            handleChangeSidebarContent({
                type: taskOfAutomaticProcessingView,
                title: t(`${tNamespace}task-view`),
                task
            })
        },
        [handleChangeSidebarContent, t]
    )

    const renderSidebarContent = useCallback(
        (projectId: string, channelsNames?: ChannelNames) => {
            if (sidebarContent === null) return null
            switch (sidebarContent.type) {
                case queueAddForm:
                    return (
                        <FormikQueueForm
                            projectId={projectId}
                            allQueues={allQueues}
                            onClose={closeSidebar}
                            categoryId={sidebarContent.categoryId}
                        />
                    )
                case queueUpdateForm:
                    return (
                        <FormikQueueForm
                            projectId={projectId}
                            queue={sidebarContent.queue}
                            allQueues={allQueues}
                            onClose={closeSidebar}
                            categoryId={sidebarContent.queue.CategoryId}
                        />
                    )
                case taskView:
                    return (
                        <TaskView
                            task={sidebarContent.task}
                            channelNames={channelsNames}
                            operator={sidebarContent.operator}
                            queue={sidebarContent.queue}
                            queues={allQueues}
                            onClose={closeSidebar}
                        />
                    )
                case taskOfAutomaticProcessingView:
                    return (
                        <TaskView
                            task={sidebarContent.task}
                            channelNames={channelsNames}
                            queues={allQueues}
                            onClose={closeSidebar}
                        />
                    )
                case operatorView:
                    return (
                        <OperatorView
                            projectId={projectId}
                            operator={sidebarContent.operator}
                            queues={allQueues}
                            onClose={closeSidebar}
                            isShowTasksEntry
                        />
                    )
                default:
                    return null
            }
        },
        [allQueues, closeSidebar, sidebarContent]
    )

    return {
        handleAddQueue,
        handleDeleteQueue,
        renderSidebarContent,
        handleTaskOfAutomaticProcessingView,
        handleUpdateQueue,
        onCloseSidebar,
        sidebarContent,
        sidebarClosed
    }
}
