import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { FileUpload } from "../../models/file"
import { User, UpdateUserRequest } from "../../models/user"
import { Form, Row, Col, Container, Button } from "react-bootstrap"
import * as Yup from "yup"
import { Formik, FormikProps } from "formik"
import { preventSubmitOnEnter } from "../../utility/common/preventSubmitOnEnter"
import { nameof } from "../../utility/common/nameof"
import "./UserProfileForm.scss"
import HorizontalValidatableInput from "../HorizontalValidatableInput/HorizontalValidatableInput"
import PhoneInput from "../PhoneInput/PhoneInput"
import PasswordInput, { PasswordValues } from "../PasswordInput/PasswordInput"
import { locales } from "../../locales"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCamera } from "@fortawesome/pro-light-svg-icons/faCamera"
import UserAvatar from "../UserAvatar/UserAvatar"
import ImageUploadModal from "../ImageUploadModal/ImageUploadModal"
import defaultAvatar from "../../assets/images/defaultUserAvatar.png"
import { WithT } from "i18next"
import { passwordRegex, passwordMinSize } from "../../utility/common/password"
import { useDispatch, useSelector } from "react-redux"
import { updateUser, updateAvatar } from "../../store/users/thunks"
import { formTranslation } from "../../locales/form"
import { selectUpdateAvatarState, selectUpdateUserState } from "../../store/users/selectors"
import { Dispatch } from "../../utility/common/storeHelper"
import LoadingButton from "../LoadingButton/LoadingButton"
import AuthenticationTokenInput from "../AuthenticationTokenInput/AuthenticationTokenInput"
import RadioButtonValidatableInput from "../RadioButtonValidatableInput/RadioButtonValidatableInput"
import useRefCheck from "../../utility/common/useRefCheck"
import { saveNotificationSuccess } from "../../store/notifications/thunks"
import { testId } from "../../utility/tests/testId"

export const tNamespace = "user:"
const nameMinSize = 2

export interface ProfileValues {
    firstName: string
    lastName: string
    phoneNumber?: string
    email: string
    language: string
    maxCapacity: number
    password?: PasswordValues
    uploadedPicture?: FileUpload<Blob>
    keyCombination?: string
}

interface Props {
    user: User
}

const MESSAGE_SEND_BEHAVIOUR_ENABLED = "EnableMessageSendBehaviour"
const CHANGES_SAVED = "notification:changes-saved"
const PROFILE_INFO_UPDATED = "notification:user-profile:profile-info-updated"

export enum SendKeyCombinations {
    Enter = "Enter",
    CtrlEnter = "Ctrl+Enter",
    ShiftEnter = "Shift+Enter"
}

const FormikUserProfileForm: React.FC<Props & FormikProps<ProfileValues> & WithT> = props => {
    const { user, t, handleSubmit } = props
    const dispatch = useDispatch<Dispatch>()

    const [show, setShow] = useState(false)
    const updateUserState = useSelector(selectUpdateUserState)
    const updateAvatarState = useSelector(selectUpdateAvatarState)
    const isMessageSendBehaviourSettingEnabled = useRefCheck(MESSAGE_SEND_BEHAVIOUR_ENABLED)

    const handleAvatarModalClose = () => setShow(false)
    const handleAvatarModalOpen = () => setShow(true)
    const uploadAvatar = async (avatar?: FileUpload<Blob>) => {
        await dispatch(updateAvatar(avatar))
        setShow(false)
    }

    const renderKeyCombinationLabel = (
        firstKey: string,
        secondKey: string,
        firstKeyLabel: string,
        secondKeyLabel: string
    ) => {
        return (
            <>
                <span>
                    <b>{firstKey}</b> {firstKeyLabel}
                </span>
                <br />
                <span>
                    <b>{secondKey}</b> {secondKeyLabel}
                </span>
            </>
        )
    }

    return (
        <Form
            className="user-profile-form"
            onSubmit={handleSubmit}
            data-test-id={testId.userProfileForm}
            onKeyPress={preventSubmitOnEnter}
        >
            <Container fluid className="p-0">
                <Row>
                    <Col className="section" md={2}>
                        <div className="section__header">{t(`${tNamespace}avatar`)}</div>
                        <div className="section__body">
                            <div className="avatar-input">
                                <div className="avatar-input__img">
                                    <UserAvatar src={user.Picture || defaultAvatar} />
                                </div>
                                <div className="avatar-input__btn">
                                    <Button className="btn-link" variant="link" onClick={handleAvatarModalOpen}>
                                        <FontAwesomeIcon className="btn-link__icon" icon={faCamera} />
                                        {t(formTranslation.change)}
                                    </Button>
                                </div>
                            </div>
                            <ImageUploadModal
                                circularCrop={true}
                                show={show}
                                onClose={handleAvatarModalClose}
                                onUpload={uploadAvatar}
                                uploading={updateAvatarState.inProcess}
                                pictureSrc={user.Picture}
                            />
                        </div>
                    </Col>
                    <Col className="section" md={5}>
                        <div className="section__header">{t(`${tNamespace}personal-info`)}</div>
                        <div className="section__body">
                            <HorizontalValidatableInput
                                id="formFirstName"
                                type="text"
                                name={nameof<ProfileValues>("firstName")}
                                label={t(formTranslation.firstName)}
                                labelCol={{ sm: 5 }}
                                inputCol={{ sm: 7 }}
                            />
                            <HorizontalValidatableInput
                                id="formLastName"
                                type="text"
                                name={nameof<ProfileValues>("lastName")}
                                label={t(formTranslation.lastName)}
                                labelCol={{ sm: 5 }}
                                inputCol={{ sm: 7 }}
                            />
                        </div>
                    </Col>
                    <Col className="section" md={5}>
                        <div className="section__header">{t(`${tNamespace}system-info`)}</div>
                        <div className="section__body">
                            <HorizontalValidatableInput
                                id="formLang"
                                as="select"
                                name={nameof<ProfileValues>("language")}
                                label={t(formTranslation.language)}
                                labelCol={{ sm: 5 }}
                                inputCol={{ sm: 7 }}
                            >
                                {locales.map(l => (
                                    <option key={l.id} value={l.id}>
                                        {l.name}
                                    </option>
                                ))}
                            </HorizontalValidatableInput>
                            <HorizontalValidatableInput
                                id="formMaxCapacity"
                                readOnly
                                type="number"
                                name={nameof<ProfileValues>("maxCapacity")}
                                label={t(formTranslation.maxConcurrentTasks)}
                                labelCol={{ sm: 5 }}
                                inputCol={{ sm: 7 }}
                            />
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col className="section" md={{ span: 5, offset: 2 }}>
                        <div className="section__header">{t(`${tNamespace}contact-info`)}</div>
                        <div className="section__body">
                            <PhoneInput name={nameof<ProfileValues>("phoneNumber")} />
                            <HorizontalValidatableInput
                                id="formEmail"
                                type="text"
                                name={nameof<ProfileValues>("email")}
                                readOnly
                                label={t(formTranslation.email)}
                                labelCol={{ sm: 5 }}
                                inputCol={{ sm: 7 }}
                            />
                        </div>
                    </Col>
                    <Col className="section" md={5}>
                        <div className="section__header">{t(`${tNamespace}security`)}</div>
                        <div className="section__body">
                            <PasswordInput name={nameof<ProfileValues>("password")} />
                            <AuthenticationTokenInput />
                        </div>
                    </Col>
                </Row>
                <Row>
                    {isMessageSendBehaviourSettingEnabled && (
                        <Col className="section" md={{ span: 5, offset: 2 }}>
                            <div className="section__header">{t(`${tNamespace}workplace-personalization`)}</div>
                            <div className="section__body">
                                <div className="section__body-text">{t(`${tNamespace}send-settings`)}</div>
                                <RadioButtonValidatableInput
                                    value={SendKeyCombinations.Enter}
                                    id="formFirstKeyCombination"
                                    name={nameof<ProfileValues>("keyCombination")}
                                    label={renderKeyCombinationLabel(
                                        SendKeyCombinations.Enter,
                                        SendKeyCombinations.ShiftEnter,
                                        t(`${tNamespace}send-settings-options.sending-message`),
                                        t(`${tNamespace}send-settings-options.new-line`)
                                    )}
                                />
                                <RadioButtonValidatableInput
                                    value={SendKeyCombinations.CtrlEnter}
                                    id="formSecondKeyCombination"
                                    name={nameof<ProfileValues>("keyCombination")}
                                    label={renderKeyCombinationLabel(
                                        SendKeyCombinations.CtrlEnter,
                                        SendKeyCombinations.Enter,
                                        t(`${tNamespace}send-settings-options.sending-message`),
                                        t(`${tNamespace}send-settings-options.new-line`)
                                    )}
                                />
                            </div>
                        </Col>
                    )}
                </Row>
                <Row className="user-profile-form__submit justify-content-md-center">
                    <Col md="auto">
                        <LoadingButton
                            type="submit"
                            className="btn-submit"
                            loading={updateUserState.inProcess}
                            variant="primary"
                        >
                            {t(formTranslation.save)}
                        </LoadingButton>
                    </Col>
                </Row>
            </Container>
        </Form>
    )
}

const defaultValues = (user: User): ProfileValues => {
    return {
        firstName: user.FirstName,
        lastName: user.LastName,
        phoneNumber: user.Phone,
        email: user.Email || user.Login,
        language: user.Locale || "ru",
        maxCapacity: user.MaxDialogs,
        keyCombination: user.KeyCombination || SendKeyCombinations.CtrlEnter
    }
}

const createRequest = (values: ProfileValues): UpdateUserRequest => {
    const request: UpdateUserRequest = {
        FirstName: values.firstName,
        LastName: values.lastName,
        Phone: values.phoneNumber,
        Locale: values.language,
        KeyCombination: values.keyCombination
    }

    if (values.password) {
        request.OldPassword = values.password.currentPassword
        request.NewPassword = values.password.newPassword
    }

    return request
}

const UserProfileForm: React.FC<Props> = props => {
    const { user } = props
    const dispatch = useDispatch()
    const { t } = useTranslation()

    return (
        <Formik
            enableReinitialize={true}
            initialValues={defaultValues(user)}
            validationSchema={Yup.object().shape({
                firstName: Yup.string()
                    .requiredExcludeEmpty(formTranslation.firstNameRequired)
                    .min(nameMinSize, formTranslation.firstNameInvalid),
                lastName: Yup.string()
                    .requiredExcludeEmpty(formTranslation.lastNameRequired)
                    .min(nameMinSize, formTranslation.lastNameInvalid),
                password: Yup.object({
                    currentPassword: Yup.string().requiredExcludeEmpty(formTranslation.currentPasswordRequired),
                    newPassword: Yup.string()
                        .requiredExcludeEmpty(formTranslation.newPasswordRequired)
                        .min(passwordMinSize, formTranslation.passwordInvalid)
                        .matches(passwordRegex, {
                            message: formTranslation.passwordInvalid,
                            excludeEmptyString: true
                        }),
                    repeatPassword: Yup.string()
                        .requiredExcludeEmpty(formTranslation.repeatPasswordRequired)
                        .oneOf(
                            [Yup.ref(nameof<PasswordValues>("newPassword")), ""],
                            formTranslation.repeatPasswordRequired
                        )
                })
                    .default(undefined)
                    .nullable()
            })}
            onSubmit={async (values: ProfileValues, { resetForm }) => {
                await dispatch(updateUser(createRequest(values)))
                resetForm()
                saveNotificationSuccess(dispatch, {
                    Title: {
                        Value: CHANGES_SAVED,
                        NeedLocalization: true
                    },
                    Description: {
                        Value: PROFILE_INFO_UPDATED,
                        NeedLocalization: true
                    }
                })
            }}
        >
            {formikProps => <FormikUserProfileForm {...props} {...formikProps} t={t} />}
        </Formik>
    )
}

export default UserProfileForm
