import React from "react"
import { ClassProps } from "../../utility/common/props"
import styles from "./OutdatedMessageItem.module.scss"
import cn from "classnames"
import { EditEnum, Message, MessageAttachment, MessageDirection } from "../../models/Dialogs/message"
import ImageAttachment from "../ImageAttachment/ImageAttachment"
import FileAttachment from "../FileAttachment/FileAttachment"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faUser, faUserCircle } from "@fortawesome/pro-light-svg-icons"
import { useTranslation } from "react-i18next"
import { getDateLocale } from "../../locales"
import { format, parseISO } from "date-fns"
import Button from "react-bootstrap/Button"
import { getLanguage } from "../../utility/common/language"
import { isImage } from "../../utility/message/IsImage"
import MessageText from "../Dialogs/DialogMessage/components/MessageText/MessageText"
import DOMPurify from "dompurify"

export interface OutdatedMessageItemProps extends ClassProps {
    message: Message
    reverseInOut?: boolean
}

const tNamespace = "dialogs:info."

const OutdatedMessageItem: React.FC<OutdatedMessageItemProps> = props => {
    const { className, message, reverseInOut } = props
    const { i18n, t } = useTranslation()
    const locale = { locale: getDateLocale(getLanguage(i18n)) }
    const isDeleteMod = message.EditType === EditEnum.Deleted

    let isOutgoing = message.Direction === MessageDirection.Outgoing
    isOutgoing = reverseInOut ? !isOutgoing : isOutgoing

    const tryGetAttachments = (message: Message, isDeleted: boolean) => {
        if (isDeleted) {
            return null
        }
        if (!message.Attachment?.Url && !message.Attachments?.length) return null

        const attachments: MessageAttachment[] = []
        if (message.Attachment) attachments.push(message.Attachment)

        if (message.Attachments) attachments.push(...message.Attachments)

        return attachments
    }

    const renderAttachment = (attachment: MessageAttachment) => {
        if (isImage(attachment)) return <ImageAttachment attachment={attachment} />

        return <FileAttachment attachment={attachment} />
    }

    const renderMessage = () => {
        if (!message.Text) {
            return null
        }

        if (isDeleteMod) {
            return (
                <MessageText
                    text={message.Text}
                    isDeleteMod={true}
                    type={message.TextType}
                    hasButtonsSection={false}
                    isWhiteFont
                />
            )
        }

        const emailRegexp = new RegExp(/^\S+@\S+\.\S+$/)
        const isEmail = emailRegexp.test(message.IdInChannel) || Boolean(message.Meta?.email_to)
        const sanitizedText = DOMPurify.sanitize(message.Text)

        if (isEmail) {
            return <div dangerouslySetInnerHTML={{ __html: sanitizedText }}></div>
        }

        return (
            <MessageText
                text={message.Text}
                isDeleteMod={isDeleteMod}
                type={message.TextType}
                hasButtonsSection={false}
                isWhiteFont
            />
        )
    }

    const attachments = tryGetAttachments(message, isDeleteMod)

    return (
        <div className={cn(styles.messageItem, isOutgoing ? styles.messageItem_out : undefined, className)}>
            <div className={styles.messageItem__body}>
                {message.Text && <div className={styles.messageItem__text}>{renderMessage()}</div>}
                {message.Actions.length > 0 && !isDeleteMod && (
                    <div className={cn(styles.messageItem__actions, styles.messageItem__section)}>
                        {message.Actions.map(v => (
                            <Button as="div" key={v.action_id} className={styles.messageItem__action} variant="light">
                                {v.action_text}
                            </Button>
                        ))}
                    </div>
                )}
                {attachments && (
                    <div className={cn(styles.messageItem__attachments, styles.messageItem__section)}>
                        {attachments.map(v => (
                            <div className={styles.messageItem__attachment} key={v.Url}>
                                {renderAttachment(v)}
                            </div>
                        ))}
                    </div>
                )}
                <div className={cn(styles.messageItem__footer, isOutgoing && styles.messageItem__footer_out)}>
                    <div className={styles.messageItem__info}>
                        <FontAwesomeIcon
                            icon={isOutgoing ? faUserCircle : faUser}
                            className={cn(styles.messageItem__icon, isOutgoing && styles.messageItem__icon_operator)}
                        />
                    </div>
                    <div className={styles.messageItem__info}>
                        {isOutgoing ? message.OperatorName : message.UserName}
                    </div>
                    <div className={styles.messageItem__info}>
                        {format(parseISO(message.Added), "dd-MM-yyyy HH:mm:ss", locale)}
                    </div>
                    {message.EditType === EditEnum.Edited && (
                        <div className={styles.messageItem__info}>{t(`${tNamespace}message-edited`)}</div>
                    )}
                </div>
            </div>
        </div>
    )
}

export default OutdatedMessageItem
