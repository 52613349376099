import React, { useContext } from "react"
import { shallowEqual, useDispatch, useSelector } from "react-redux"
import { CatalogFormValues } from "../../models/catalog"
import { createCatalog, deleteCatalog, updateCatalog } from "../../store/knowledgeBase/thunks"
import CatalogPopover from "./CatalogPopover"
import AsyncState from "../../core/asyncState"
import {
    selectCreateCatalogState,
    selectDeleteCatalogState,
    selectUpdateCatalogState
} from "../../store/knowledgeBase/selectors"
import KnowledgeBasePermissionsContext from "../KnowledgeBasePermissionsProvider/KnowledgeBasePermissionsContext"
import KnowledgeBaseContext from "../KnowledgeBaseProvider/KnowledgeBaseContext"
import useDownloadKBCatalogFile from "../../utility/knowledgeBase/useDownloadKBCatalogFile"
import { DocType } from "../../models/knowledgeBase/converter"
import { useAppSelector } from "../../store/hooks"
import { selectCurrentProjectId } from "../../store/projects/selectors"
import { toggleCatalogNewsline } from "../../store/knowledgeBase/thunks/newsline"

interface CatalogPopoverContainerProps {
    catalogCode?: string
    catalogTitle?: string
    isEdit?: boolean
    onEditClick?: (catalogId: string) => void
    onCancelEditClick?: () => void
    isWideAddButton?: boolean
    isNewsline?: boolean
}

const CatalogPopoverContainer: React.FC<CatalogPopoverContainerProps> = props => {
    const { catalogCode, catalogTitle, isEdit, onEditClick, onCancelEditClick, isWideAddButton, isNewsline } = props
    const dispatch = useDispatch()
    const apiState = AsyncState.merge3(
        useSelector(selectCreateCatalogState, shallowEqual),
        useSelector(selectUpdateCatalogState, shallowEqual),
        useSelector(selectDeleteCatalogState, shallowEqual)
    )
    const { permissions, withNested } = useContext(KnowledgeBasePermissionsContext)
    const { permittedActionOptions } = useContext(KnowledgeBaseContext)
    const projectId = useAppSelector(selectCurrentProjectId)

    const handleCreateCatalog = (values: CatalogFormValues, callbackFn: () => void) => {
        projectId && dispatch(createCatalog(projectId, values, callbackFn))
    }

    const handleUpdateCatalog = (values: CatalogFormValues, callbackFn: () => void) => {
        projectId && catalogCode && dispatch(updateCatalog(projectId, catalogCode, values, callbackFn))
    }

    const handleDeleteCatalog = (callbackFn: () => void) => {
        projectId && catalogCode && dispatch(deleteCatalog(projectId, catalogCode, callbackFn))
    }

    const handleToggleNewsline = (callbackFn?: () => void) => {
        projectId && catalogCode && dispatch(toggleCatalogNewsline(projectId, catalogCode, callbackFn))
    }

    const { handleDownloadCatalog, downloadLink } = useDownloadKBCatalogFile(DocType.Word, projectId, catalogCode)

    const onEditClickTmp = () => {
        if (!onEditClick || !catalogCode) {
            return
        }

        onEditClick(catalogCode)
    }

    return (
        <>
            <CatalogPopover
                onEditClick={onEditClickTmp}
                onCancelEditClick={onCancelEditClick}
                onCreateCatalog={handleCreateCatalog}
                onEditCatalog={handleUpdateCatalog}
                onDownloadClick={handleDownloadCatalog}
                onDeleteCatalog={handleDeleteCatalog}
                onToggleNewsline={handleToggleNewsline}
                catalogTitle={catalogTitle}
                isEdit={isEdit}
                loading={apiState.inProcess}
                permissions={permissions}
                permissionsWithNested={withNested}
                permittedActionOptions={permittedActionOptions}
                isWideAddButton={isWideAddButton}
                isNewsline={isNewsline}
            />
            {downloadLink}
        </>
    )
}

export default CatalogPopoverContainer
