import React from "react"
import { Comparison, ComparisonType } from "../../models/articleCompare"
import cn from "classnames"
import styles from "./ArticleTitle.module.scss"
import { testId } from "../../utility/tests/testId"

export interface ArticleTitleProps {
    isExpanded: boolean
    headerTop: React.ReactNode
    title: React.ReactNode | string
    headerRight: React.ReactNode
    titleComparison?: Comparison<string>[]
}

const comparisonTitleWorlds = {
    Added: "articleHeader__title_added",
    Removed: "articleHeader__title_removed",
    Replaced: "articleHeader__title_replaced",
    Unmodified: "articleHeader__title_unmodified",
    Ignored: "articleHeader__title_unmodified"
}

const processTitleComparison = (comparison: Comparison<string>[]) => {
    return comparison.reduce((accum, comparisonText) => {
        const type = comparisonText.ComparisonType
        const text = type !== ComparisonType.Replaced ? comparisonText.Value : comparisonText.ReplacedValue
        const styleString = comparisonTitleWorlds[type]
        return accum + `<span class=${styles[styleString]}>` + text + "</span>"
    }, "")
}

const ArticleTitle: React.FC<ArticleTitleProps> = props => {
    const { isExpanded, headerTop, headerRight, title, titleComparison } = props
    const comparisonText = titleComparison ? processTitleComparison(titleComparison) : ""

    return (
        <div
            className={cn(styles.articleHeader, isExpanded && styles.articleHeader_expanded)}
            data-testid={testId.articleHeader}
        >
            <div className={styles.articleHeader__headerTop}>{headerTop}</div>
            <div className={styles.articleHeader__headerSection}>
                {comparisonText.length > 0 ? (
                    <div
                        className={styles.articleHeader__title}
                        dangerouslySetInnerHTML={{ __html: comparisonText }}
                    ></div>
                ) : (
                    <div className={styles.articleHeader__title}>{title}</div>
                )}
                {headerRight && <div className={styles.articleHeader__headerRight}>{headerRight}</div>}
            </div>
        </div>
    )
}

export default ArticleTitle
