import { createBrowserHistory } from "history"
import { routerMiddleware as createRouterMiddleware } from "connected-react-router"
import { ThunkAction, configureStore, createListenerMiddleware } from "@reduxjs/toolkit"
import "../utility/common/setupYupCustomValidations"
import createRootReducer from "./rootReducer"
import { queryApi } from "../api/api"
import { Action, AnyAction, Dispatch } from "redux"
import { createLogger } from "redux-logger"
import objsEq from "lodash/isEqual"

export const history = createBrowserHistory({
    basename: process.env.PUBLIC_URL + "/"
})

const routerMiddleware = createRouterMiddleware(history)
const middlewares = [routerMiddleware, queryApi.middleware]

if (process.env.NODE_ENV === "development") {
    const logger = createLogger({
        collapsed: true,
        duration: true,
        timestamp: true
    })

    middlewares.push(logger)
}

export const listenerMiddleware = createListenerMiddleware()

export type TAnyActionMod<T extends unknown> = AnyAction & {
    payload: T
}

export type TRunOneOffWatcherFallbackFn<T extends unknown> = (action: TAnyActionMod<T>, dispatch: Dispatch) => void

export type TRunOneOffWatcherArgs = {
    actionType: string
    endpointName: string
    args?: Record<string, unknown>
}

const runOneOffWatcher =
    <T extends unknown>({ actionType, endpointName, args = {} }: TRunOneOffWatcherArgs) =>
    (fn: TRunOneOffWatcherFallbackFn<T>) =>
        listenerMiddleware.startListening({
            predicate: action => {
                if (action.type.split("/")[0] === actionType) {
                    if (action?.meta?.arg && action.meta.arg.endpointName === endpointName) {
                        if (Object.keys(args).length) {
                            return objsEq(args, action.meta.arg.originalArgs)
                        }

                        return true
                    }
                }

                return false
            },
            effect: async (action, listenerApi) => {
                fn(action as unknown as TAnyActionMod<T>, listenerApi.dispatch)
                listenerApi.unsubscribe()
            }
        })

const runWatchAll = <T extends unknown>(fn: TRunOneOffWatcherFallbackFn<T>) =>
    listenerMiddleware.startListening({
        predicate: () => true,
        effect: async (action, listenerApi) => {
            fn(action as unknown as TAnyActionMod<T>, listenerApi.dispatch)
        }
    })

const runOneOffWatcherThenable = <T extends unknown>({ actionType, endpointName, args = {} }: TRunOneOffWatcherArgs) =>
    new Promise<{ action: TAnyActionMod<T>; dispatch: Dispatch }>(resolve =>
        runOneOffWatcher<T>({ actionType, endpointName, args })((action, dispatch) => {
            resolve({
                action,
                dispatch
            })
        })
    )

middlewares.push(listenerMiddleware.middleware)

const store = configureStore({
    reducer: createRootReducer(history),
    middleware: getDefaultMiddleware => getDefaultMiddleware({ serializableCheck: false }).concat(middlewares),
    devTools: process.env.NODE_ENV === "development"
})

export type Store = typeof store
export type AppState = ReturnType<typeof store.getState>
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>

export type AppDispatch = typeof store.dispatch

export { runOneOffWatcher, runOneOffWatcherThenable, runWatchAll }
export default store
