import React, { useCallback, useEffect, useState } from "react"
import "./ValidatableInput.scss"
import { useField } from "formik"
import { Col, ColProps, Form, Row } from "react-bootstrap"
import cn from "classnames"
import { ValidatableInputProps } from "./ValidatableInput"
import debounce from "lodash/debounce"
import ConditionalWrapper from "../ConditionalWrapper/ConditionalWrapper"
import { useTranslation } from "react-i18next"
import AlertIcon from "../AlertIcon/AlertIcon"
import { FormValue } from "../../types/FormValue"
import { BaseValidatableInputProps } from "../ValidatableInputBase/ValidatableInputBase"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCaretDown } from "@fortawesome/pro-light-svg-icons/faCaretDown"
import { faExclamationCircle } from "@fortawesome/pro-light-svg-icons/faExclamationCircle"

export type DebouncedFormControlProps = BaseValidatableInputProps

export const DebouncedFormControl: React.FC<DebouncedFormControlProps> = props => {
    const { as, children, onChange, value } = props
    const [innerValue, setInnerValue] = useState(value)

    useEffect(() => {
        setInnerValue(value)
    }, [value])

    const debounced = useCallback(
        debounce((event: React.ChangeEvent<HTMLInputElement>) => {
            onChange?.(event)
        }, 200),
        []
    )

    const handleOnChange = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            event.persist()
            setInnerValue(event.currentTarget.value)
            debounced(event)
        },
        [debounced]
    )

    return (
        <>
            <Form.Control {...props} as={as} onChange={handleOnChange} value={innerValue ?? ""}>
                {children}
            </Form.Control>
        </>
    )
}

function DebouncedValidatableInput(props: DebouncedValidatableInputProps) {
    const { id, icon, label, inputCol, labelCol, alert, defaultValue, cols, rows, ...inputProps } = props
    const { t } = useTranslation()
    const [field, meta, helper] = useField<FormValue>(inputProps)
    const isSelect = inputProps.as === "select"

    // defaultValue - проп может быть задан только для инпута с типом select, т.к. только у него пустая строка в поле
    // может быть только при инициализации. Так же его можно использовать только в том случае, когда задать defaultValue через initialValue Formick'a(99% случаев) нельзя
    useEffect(() => {
        if (isSelect && defaultValue && !inputProps.value && !field.value) {
            helper.setValue(defaultValue)
        }
    }, [defaultValue, inputProps.value, field.value])

    const error = meta.touched && meta.error ? t(meta.error) : undefined

    return (
        <Form.Group
            className={cn(
                "validatable-input",
                error && "validatable-input_invalid",
                alert && "validatable-input_with-alert",
                inputProps.className
            )}
            as={labelCol && Row}
            controlId={id}
        >
            {(label || icon) && (
                <Form.Label column={!!labelCol} {...labelCol}>
                    <>
                        {label}
                        {icon}
                    </>
                </Form.Label>
            )}
            <ConditionalWrapper
                conditionValue={inputCol}
                wrapper={(children, inputCol) => <Col {...inputCol}>{children}</Col>}
            >
                <div className="validatable-input__wrap">
                    <DebouncedFormControl
                        {...field}
                        {...inputProps}
                        className={isSelect ? "select-input" : undefined}
                        rows={rows}
                        cols={cols}
                        isInvalid={!!error}
                        value={field.value}
                    />
                    {isSelect && (
                        <div className="validatable-input__icon validatable-input__icon_arrow">
                            <FontAwesomeIcon icon={faCaretDown} />
                        </div>
                    )}
                    {isSelect && error && (
                        <div className="validatable-input__icon validatable-input__icon_alert">
                            <FontAwesomeIcon icon={faExclamationCircle} />
                        </div>
                    )}
                    <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
                </div>
            </ConditionalWrapper>
            {alert && <AlertIcon id={`${id}-alert`} content={error} className="validatable-input__alert" />}
        </Form.Group>
    )
}

export interface DebouncedValidatableInputProps extends ValidatableInputProps {
    inputCol?: ColProps
    labelCol?: ColProps
    alert?: boolean
    defaultValue?: FormValue
}

export default DebouncedValidatableInput
