import React from "react"
import styles from "./QueueInfoItem.module.scss"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { IconProp } from "@fortawesome/fontawesome-svg-core"
import { useTranslation } from "react-i18next"
import { TFunction } from "i18next"
import cn from "classnames"
import { ClassProps } from "../../utility/common/props"

const tNamespace = "queues:info."

type QueueInfoItemVariant = "info" | "error"

export interface QueueInfoItemProps extends ClassProps {
    title: string
    icon: IconProp
    value?: number | string
    unit?: string
    period?: string
    target?: number
    variant?: QueueInfoItemVariant
    description?: string
    testId?: string
    isSmall?: boolean
    isSelectable?: boolean
    onClick?: () => void
}

function renderValue(props: QueueInfoItemProps, t: TFunction) {
    const { value, unit, period, testId, isSmall } = props

    if (value === undefined)
        return <div className={styles.queueInfoItem__value}>{!isSmall ? t(`${tNamespace}no-data`) : "–"}</div>

    return (
        <>
            <div className={styles.queueInfoItem__value} data-test-id={testId}>
                {value + (unit || "")}
            </div>
            {period && <div className={styles.queueInfoItem__period}>{" " + period}</div>}
        </>
    )
}

const QueueInfoItem: React.FC<QueueInfoItemProps> = props => {
    const { t } = useTranslation()
    const {
        title,
        icon,
        target,
        unit,
        variant = "info",
        description,
        isSmall = false,
        isSelectable = false,
        onClick
    } = props

    return (
        <div
            className={cn(
                styles.queueInfoItem,
                isSmall && styles.queueInfoItem_small,
                isSelectable && styles.queueInfoItem_selectable
            )}
            onClick={onClick}
        >
            <div className={styles.queueInfoItem__header}>
                <div className={cn(styles.queueInfoItem__title, isSmall && styles.queueInfoItem__title_small)}>
                    {title}
                </div>
                {target && (
                    <div className={styles.queueInfoItem__target}>{`${t(
                        `${tNamespace}target`
                    )}: ${target}${unit}`}</div>
                )}
            </div>
            <div className={styles.queueInfoItem__body}>
                <div
                    className={cn(styles.queueInfoItem__icon, variant === "error" && styles.queueInfoItem__icon_error)}
                >
                    <FontAwesomeIcon size={"lg"} icon={icon} />
                </div>
                {renderValue(props, t)}
            </div>
            {description && <div className={styles.queueInfoItem__description}>{description}</div>}
        </div>
    )
}

export default QueueInfoItem
