import { IconProp } from "@fortawesome/fontawesome-svg-core"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"
import styles from "./SettingsContextMenuItem.module.scss"
import cn from "classnames"
import TooltipTrigger from "../TooltipTrigger/TooltipTrigger"
import Toggle from "../Toggle/Toggle"

export interface SettingsContextMenuItemProps {
    icon?: IconProp
    text: string
    id: string
    useSeparator?: boolean
    danger?: boolean
    onClick?: (e: React.SyntheticEvent) => void
    testId?: string
    unavailableMessage?: string
    toggleEnabled?: boolean
    toggleActive?: boolean
    toggleLoading?: boolean
}

const tooltipDelay = { show: 450, hide: 0 }

const SettingsContextMenuItem: React.FC<SettingsContextMenuItemProps> = props => {
    const {
        id,
        icon,
        text,
        useSeparator,
        danger,
        onClick,
        testId,
        unavailableMessage,
        toggleEnabled = false,
        toggleActive = false,
        toggleLoading = false
    } = props

    return (
        <TooltipTrigger
            id={id}
            condition={!!unavailableMessage}
            content={unavailableMessage}
            placement="auto"
            delay={tooltipDelay}
        >
            <div
                className={cn(
                    styles.item,
                    useSeparator && styles.item_separated,
                    danger && styles.item_danger,
                    !!unavailableMessage && styles.item_disabled
                )}
                onClick={!unavailableMessage ? onClick : undefined}
                data-test-id={testId}
            >
                {icon && <FontAwesomeIcon icon={icon} className={styles.item__icon} />}
                <div className={styles.item__text}>{text}</div>
                {toggleEnabled && (
                    <Toggle
                        checked={toggleActive}
                        onChange={onClick}
                        small
                        className={cn(toggleLoading && styles.item__toggle_loading)}
                        disabled={!!unavailableMessage}
                    ></Toggle>
                )}
            </div>
        </TooltipTrigger>
    )
}

export default SettingsContextMenuItem
