import React from "react"
import CatalogPopover from "./CatalogPopover"
import { CatalogFormValues } from "../../models/catalog"
import {
    convertCreateQueueCategoryRequest,
    convertUpdateQueueCategoryRequest
} from "../../utility/queues/queueCategoryForm"
import { useDispatch } from "react-redux"
import { createQueueCategory, removeQueueCategory, updateQueueCategory } from "../../store/queues/thunks"
import { getPermittedActionOptions } from "../../utility/knowledgeBase/knowledgeBase"
import { useTranslation } from "react-i18next"
import { KnowledgeBasePermission } from "../../models/knowledgeBase/permission"

const tNamespace = "queues:category."

interface QueueCatalogPopoverContainerProps {
    projectId: string
    catalogId?: string
    catalogTitle?: string
    isEdit?: boolean
    onEditClick?: (catalogId: string) => void
    onCancelEditClick?: () => void
    isRemoveDisabled?: boolean
    permissions?: KnowledgeBasePermission[]
    permissionsWithNested?: boolean
    isRemoveUnavailable?: boolean
}

const QueueCatalogPopoverContainer: React.FC<QueueCatalogPopoverContainerProps> = props => {
    const {
        projectId,
        catalogId,
        catalogTitle,
        permissions = [],
        isEdit = false,
        onEditClick,
        onCancelEditClick,
        isRemoveDisabled,
        isRemoveUnavailable,
        permissionsWithNested = false
    } = props
    const { t } = useTranslation()
    const dispatch = useDispatch()

    const handleCreateQueueCatalog = (values: CatalogFormValues, callbackFn: () => void) => {
        const request = convertCreateQueueCategoryRequest(values)
        dispatch(createQueueCategory(projectId, request, callbackFn))
    }
    const handleUpdateQueueCatalog = (values: CatalogFormValues, callbackFn: () => void) => {
        const request = convertUpdateQueueCategoryRequest(values)
        if (catalogId) {
            dispatch(updateQueueCategory(projectId, catalogId, request, callbackFn))
        }
    }
    const handleDeleteQueueCatalog = (callbackFn: () => void) => {
        if (catalogId) {
            dispatch(removeQueueCategory(projectId, catalogId, callbackFn))
        }
    }

    const onEditClickTmp = () => {
        if (!onEditClick || !catalogId) {
            return
        }

        onEditClick(catalogId)
    }

    const unavailableRemovingMessage = isRemoveUnavailable ? t(`${tNamespace}remove`) : undefined

    return (
        <CatalogPopover
            onEditClick={onEditClickTmp}
            onCancelEditClick={onCancelEditClick}
            permittedActionOptions={getPermittedActionOptions(t)}
            permissions={permissions}
            permissionsWithNested={permissionsWithNested}
            onCreateCatalog={handleCreateQueueCatalog}
            onDeleteCatalog={handleDeleteQueueCatalog}
            onEditCatalog={handleUpdateQueueCatalog}
            catalogTitle={catalogTitle}
            isEdit={isEdit}
            disableProjectsSelector
            unavailableRemovingMessage={unavailableRemovingMessage}
            disableRemoving={isRemoveDisabled}
            disabledPermInheritance
        />
    )
}

export default QueueCatalogPopoverContainer
