import React, { useCallback, useEffect, useMemo, useState } from "react"
import { shallowEqual, useDispatch } from "react-redux"
import { useRouteMatch } from "react-router-dom"
import { useTranslation } from "react-i18next"
import PageLayout from "../../components/PageLayout/PageLayout"
import {
    getArticle,
    getArticleTypes,
    getCatalogs,
    getFavoriteArticlesBlock,
    getRelatedCategories
} from "../../store/knowledgeBase/thunks"
import { selectCurrentProjectId } from "../../store/projects/selectors"
import Catalogs, { CatalogPlacement } from "../../components/Catalogs/Catalogs"
import ArticleViewContainer from "../../components/ArticleView/ArticleViewContainer"
import SearchQueryArticlesContainer from "../../components/SearchQueryArticlesContainer/SearchQueryArticlesContainer"
import {
    selectCurrentArticleState,
    selectCurrentBranch,
    selectKnowledgeBaseWorkMode
} from "../../store/knowledgeBase/selectors"
import { selectSlots } from "../../store/slots/selectors"
import { KnowledgeBaseWorkMode } from "../../models/knowledgeBase/workMode"
import { setCurrentArticlesFilter, setKnowledgeBaseWorkMode } from "../../store/knowledgeBase/slice"
import SearchArticlesInputContainer from "../../components/SearchArticlesInput/SearchArticlesInputContainer"
import styles from "./KnowledgeBaseInWorkplace.module.scss"
import { ArticleScenarioContext, ScenarioContext } from "../../components/ScenarioEditor/ScenarioContext"
import { workplaceKnowledgeBaseHub } from "../../api/instances/workplaceKnowledgeBaseHub"
import { getSlotsMap } from "../../utility/scenario/scenarioGraph"
import { onChooseAnswerFunc, onSendReplyFunc } from "../../types/knowledgeBaseFunctions"
import KnowledgeBaseProvider from "../../components/KnowledgeBaseProvider/KnowledgeBaseProvider"
import useKnowledgeBaseSidebar, { SelectSlotFunction } from "../KnowledgeBase/KnowledgeBaseSidebar"
import { getSlots } from "../../store/slots/thunks"
import SidebarRight from "../../components/SidebarRight/SidebarRight"
import { useAppSelector } from "../../store/hooks"
import { selectDialogId, selectDialogKnowledgeBaseState } from "../../store/dialogs/selectors"
import { actions } from "../../store/dialogs/slice"
import { actions as knowledgeBaseActions, mutateCurrentBranch } from "../../store/knowledgeBase/slice"
import { TreeItem } from "@atlaskit/tree"
import { selectCurrentUserLogin } from "../../store/users/selectors"
import { ArticleContext } from "../KnowledgeBase/KnowledgeBase"
import ArticlesFavoriteBlock from "../../components/ArticlesFavoriteBlock/ArticlesFavoriteBlock"
import { useGetDialogApiCachedEndpointData } from "../../api/selectors"
import { useLazyGetQueueExtendedSettingsQuery } from "../../api/controllers/queues"
import { ArticleKind } from "../../models/article"

const tNamespace = "dialogs:"

export interface ArticleContextType {
    onAddSlot: (onSelect: SelectSlotFunction) => void
    onThreadOpen: onSendReplyFunc
    onChooseAnswer: onChooseAnswerFunc
    onOpenQuestions: (isEditable: boolean) => void
}
export const articleContextDefaultValue: ArticleContextType = {
    onAddSlot: () => void 0,
    onThreadOpen: () => void 0,
    onChooseAnswer: () => void 0,
    onOpenQuestions: () => void 0
}

interface KnowledgeBaseInWorkplaceProps {
    catalogPlacement?: CatalogPlacement
}

const KnowledgeBaseInWorkplace = ({ catalogPlacement = CatalogPlacement.workplace }: KnowledgeBaseInWorkplaceProps) => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const { url } = useRouteMatch()
    const workMode = useAppSelector(selectKnowledgeBaseWorkMode)
    const projectId = useAppSelector(selectCurrentProjectId) as string
    const login = useAppSelector(selectCurrentUserLogin) as string
    const { CurrentCatalogCode, CurrentCategoryId } = useAppSelector(selectCurrentBranch)
    const articleState = useAppSelector(selectCurrentArticleState, shallowEqual)
    const { isKnowledgeBaseSidebarOpened, isArticleSidebarOpened } = useAppSelector(selectDialogKnowledgeBaseState)
    const [connected, setConnected] = useState<boolean>(false)
    const [scenarioTouched, setScenarioTouched] = useState<boolean>(false)

    const {
        sidebarClosed,
        closeSidebar,
        onCloseSidebar,
        selectedCondition,
        sidebarContent,
        setSelectedCondition,
        selectedNode,
        setSelectedNode,
        elements,
        setElements,
        instance,
        setInstance,
        handleArticleCommentThreadOpen,
        handleAddBlock,
        handleAddSlot,
        handleAddSlotInScenario,
        handleOpenConditionForm,
        handleOpenAgentForm,
        handleOpenSetSlotValueForm,
        handleOpenDeleteSlotValueForm,
        handleChooseAnswer,
        handleOpenQuestions,
        handleOpenCatalogSharing,
        renderSidebarContent
    } = useKnowledgeBaseSidebar(dispatch, t, articleState, projectId)

    const dialogId = useAppSelector(selectDialogId)
    const queueId = useGetDialogApiCachedEndpointData("getDialog", dialogId).data?.Queue?.Id

    const [loadQueueExtendedSettings, { data: queueExtendedSettings }] = useLazyGetQueueExtendedSettingsQuery()

    useEffect(() => {
        if (projectId && queueId) {
            loadQueueExtendedSettings({
                projectId,
                queueId: queueId
            })
        }
    }, [projectId, queueId])

    useEffect(() => {
        if (projectId) {
            dispatch(getCatalogs(projectId, false))
            dispatch(getArticleTypes(projectId))
            dispatch(getSlots(projectId))
            dispatch(getFavoriteArticlesBlock(projectId))
        }
    }, [dispatch, projectId])

    useEffect(() => {
        return () => {
            workMode === KnowledgeBaseWorkMode.Search &&
                dispatch(setKnowledgeBaseWorkMode(KnowledgeBaseWorkMode.CatalogsView))
        }
    }, [dispatch, workMode])

    useEffect(() => {
        setCurrentArticlesFilter({})
    }, [])

    useEffect(() => {
        workplaceKnowledgeBaseHub.connect().then(() => {
            setConnected(true)
            workplaceKnowledgeBaseHub.setReconnectingCallback(() => setConnected(false))
            workplaceKnowledgeBaseHub.setReconnectedCallback(() => setConnected(true))
        })

        return () => {
            workplaceKnowledgeBaseHub.disconnect()
            setConnected(false)
        }
    }, [])

    useEffect(() => {
        workplaceKnowledgeBaseHub.setPageUrl(url)
    }, [url])

    const handleSubscribeToArticle = useCallback((articleCode: string) => {
        workplaceKnowledgeBaseHub.subscribe(articleCode)
    }, [])

    const handleUnsubscribeFromArticle = useCallback((articleCode: string) => {
        workplaceKnowledgeBaseHub.unsubscribe(articleCode)
    }, [])

    const slots = useAppSelector(selectSlots)
    const slotsMap = useMemo(() => getSlotsMap(slots), [slots])

    const onCloseClick = useCallback(() => {
        sidebarContent?.onCloseSidebar && sidebarContent.onCloseSidebar()
        onCloseSidebar()
    }, [onCloseSidebar, sidebarContent])

    const handleCloseKnowledgeBaseSidebar = useCallback(() => {
        dispatch(actions.closeKnowledgeBaseSidebar())
    }, [dispatch])

    const handleCloseArticleSidebar = useCallback(() => {
        dispatch(actions.closeArticleSidebar())
    }, [dispatch])

    const handleCloseAllSidebars = useCallback(() => {
        dispatch(actions.closeKnowledgeBaseSidebar())
        dispatch(actions.closeArticleSidebar())
    }, [dispatch])

    const handleLoadArticle = useCallback(
        (code: string) => {
            dispatch(actions.openArticleSidebar())
            dispatch(getRelatedCategories(projectId, CurrentCategoryId, code, CurrentCatalogCode))
            dispatch(getArticle(projectId, login, code, false))
        },
        [dispatch, login, projectId, CurrentCategoryId, CurrentCatalogCode]
    )

    const handleChangeCurrentAnswer = useCallback(
        (answerId: string) => {
            dispatch(knowledgeBaseActions.updateCurrentAnswerById(answerId))
            closeSidebar()
        },
        [dispatch, closeSidebar]
    )

    const handleRelatedArticleClick = useCallback(
        (category: TreeItem) => {
            const {
                id,
                data: { symbolCode, catalogCode }
            } = category

            handleLoadArticle(symbolCode)
            dispatch(
                mutateCurrentBranch({
                    CurrentCategoryId: id as string,
                    CurrentCatalogCode: catalogCode
                })
            )
        },
        [dispatch, handleLoadArticle]
    )

    const handleReloadArticle = useCallback(() => {
        const articleSymbolCode = articleState.data?.Article?.SymbolCode
        if (articleSymbolCode) {
            handleLoadArticle(articleSymbolCode)
        }
    }, [articleState, handleLoadArticle])

    return (
        // TODO: Убрать вложенность контекстов, заменив их на редакс стейт
        <KnowledgeBaseProvider onOpenSharingCatalog={handleOpenCatalogSharing} projectId={projectId} url={url} t={t}>
            <SidebarRight
                title={t(`${tNamespace}info.knowledge-base`)}
                open={isKnowledgeBaseSidebarOpened}
                onClose={handleCloseKnowledgeBaseSidebar}
                contentClassName={styles.knowledgeBaseInWorkplace__sidebar}
            >
                <SearchArticlesInputContainer className={styles.knowledgeBaseInWorkplace__searchContainer} />
                <ArticlesFavoriteBlock projectId={projectId} url={url} onClickCategoryTitle={handleLoadArticle} />
                {workMode === KnowledgeBaseWorkMode.Search ? (
                    <SearchQueryArticlesContainer
                        catalogPlacement={catalogPlacement}
                        onArticleClick={handleLoadArticle}
                    />
                ) : (
                    <Catalogs
                        catalogPlacement={catalogPlacement}
                        onClickCategoryTitle={handleLoadArticle}
                        allowedCatalogs={queueExtendedSettings?.AllowCatalogs?.Allowed}
                        excludeKind={ArticleKind.Scenario}
                    />
                )}
            </SidebarRight>
            <SidebarRight
                title={articleState.data?.Article?.Title || ""}
                open={isArticleSidebarOpened}
                onBack={handleCloseArticleSidebar}
                onClose={handleCloseAllSidebars}
            >
                <ScenarioContext.Provider
                    value={{
                        elements,
                        setElements,
                        instance,
                        setInstance,
                        selectedNode,
                        scenarioTouched,
                        setSelectedNode,
                        setScenarioTouched,
                        handleAddBlock,
                        projectId,
                        closeSidebar
                    }}
                >
                    <ArticleScenarioContext.Provider
                        value={{
                            handleAddSlot: handleAddSlotInScenario,
                            handleOpenConditionForm,
                            handleOpenAgentForm,
                            handleOpenSetSlotValueForm,
                            handleOpenDeleteSlotValueForm,
                            slotsMap,
                            selectedCondition,
                            setSelectedCondition
                        }}
                    >
                        <PageLayout.Content overlappingSidebar>
                            <ArticleContext.Provider
                                value={{
                                    onAddSlot: handleAddSlot,
                                    onThreadOpen: handleArticleCommentThreadOpen,
                                    onChooseAnswer: handleChooseAnswer,
                                    onOpenQuestions: handleOpenQuestions,
                                    isInWorkplace: true
                                }}
                            >
                                <ArticleViewContainer
                                    onSubscribeToArticle={handleSubscribeToArticle}
                                    onUnsubscribeFromArticle={handleUnsubscribeFromArticle}
                                    disableAddBlockBtn={!sidebarClosed}
                                    connected={connected}
                                    handleChangeCurrentAnswer={handleChangeCurrentAnswer}
                                    handleRelatedArticleClick={handleRelatedArticleClick}
                                    handleReloadArticle={handleReloadArticle}
                                    articlePlacement={CatalogPlacement.workplace}
                                />
                            </ArticleContext.Provider>
                        </PageLayout.Content>
                    </ArticleScenarioContext.Provider>
                </ScenarioContext.Provider>
            </SidebarRight>
            <SidebarRight
                title={sidebarContent ? sidebarContent.title : ""}
                open={!sidebarClosed}
                onClose={onCloseSidebar}
                onCloseClick={onCloseClick}
                onBack={sidebarContent?.onBack}
            >
                {renderSidebarContent()}
            </SidebarRight>
        </KnowledgeBaseProvider>
    )
}

export default KnowledgeBaseInWorkplace
