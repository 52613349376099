import React from "react"
import { WithT } from "i18next"
import { ExtraSettings, ExtraSettingsValue } from "../../../../models/queueValues"
import styles from "./ExtraSettingWrapper/ExtraSettingWrapper.module.scss"
import TimeoutControl from "../../../ProjectSettings/TimeoutControl"
import QueueClientSurveys from "./QueueClientSurveys/QueueClientSurveys"
import QueueAutoGreeting from "./QueueAutoGreeting/QueueAutoGreeting"
import ExtraSettingWrapper from "./ExtraSettingWrapper/ExtraSettingWrapper"
import QueueEmailResendConfig from "./QueueEmailResendConfig/QueueEmailResendConfig"
import QueueEmailChannels from "./QueueEmailChannels/QueueEmailChannels"
import QueueFastReplyTemplates from "./QueueFastReplyTemplates/QueueFastReplyTemplates"
import QueueAutoHolding from "./QueueAutoHolding/QueueAutoHolding"
import QueueDialogGroups from "./QueueDialogGroups/QueueDialogGroups"
import QueueAllowCatalogs from "./QueueAllowCatalogs/QueueAllowCatalogs"
import QueueDialogFinish from "./QueueDialogFinish/QueueDialogFinish"

const tNamespace = "queues:settings."
const tTooltipNamespace = "project:project-settings.operators.tooltip"

interface Props extends WithT {
    index: number
    onDelete: () => void
    setting: ExtraSettingsValue
}

const QueueExtraSetting: React.FC<Props> = props => {
    const { t, index, onDelete, setting } = props

    const renderContent = () => {
        switch (setting.type) {
            case ExtraSettings.DialogTimer:
                const greenTimer = `extraSettings[${index}].value.greenTimer`
                const yellowTimer = `extraSettings[${index}].value.yellowTimer`

                return (
                    <ExtraSettingWrapper title={t(`${tNamespace}dialog-timer-title`)} onDelete={onDelete}>
                        <div className={styles.extraSetting__title}>{t(`${tNamespace}dialog-timer-green`)}</div>
                        <TimeoutControl id={greenTimer} name={greenTimer} />
                        <div className={styles.extraSetting__title}>{t(`${tNamespace}dialog-timer-yellow`)}</div>
                        <TimeoutControl id={yellowTimer} name={yellowTimer} />
                    </ExtraSettingWrapper>
                )
            case ExtraSettings.AutoGreeting:
                const templateName = `extraSettings[${index}].value.template`

                return (
                    <ExtraSettingWrapper title={t(`${tNamespace}autogreeting-title`)} onDelete={onDelete}>
                        <QueueAutoGreeting
                            templateName={templateName}
                            settingValue={setting.value}
                            t={t}
                            index={index}
                        />
                    </ExtraSettingWrapper>
                )
            case ExtraSettings.ClientSurvey:
                const mainSurveyId = `extraSettings[${index}].value.mainSurveyId`
                const shortSurveyId = `extraSettings[${index}].value.shortSurveyId`

                return (
                    <ExtraSettingWrapper title={t(`${tNamespace}client-survey-title`)} onDelete={onDelete}>
                        <QueueClientSurveys t={t} mainSurveyId={mainSurveyId} shortSurveyId={shortSurveyId} />
                    </ExtraSettingWrapper>
                )
            case ExtraSettings.AdditionalResendConfig:
                const freeFilling = `extraSettings[${index}].value.enable`
                const emailTemplate = `extraSettings[${index}].value.template`

                return (
                    <ExtraSettingWrapper title={t(`${tNamespace}email-resend-config-title`)} onDelete={onDelete}>
                        <QueueEmailResendConfig
                            enabledFreeFilling={freeFilling}
                            template={emailTemplate}
                            t={t}
                        ></QueueEmailResendConfig>
                    </ExtraSettingWrapper>
                )
            case ExtraSettings.EmailChannels:
                const defaultChannelTemplate = `extraSettings[${index}].value.defaultChannelId`
                const additionalChannelsTemplate = `extraSettings[${index}].value.additionalChannelsIds`

                return (
                    <ExtraSettingWrapper title={t(`${tNamespace}email-channels-title`)} onDelete={onDelete}>
                        <QueueEmailChannels
                            defaultChannelTemplate={defaultChannelTemplate}
                            additionalChannelsTemplate={additionalChannelsTemplate}
                            t={t}
                            values={setting.value}
                        />
                    </ExtraSettingWrapper>
                )
            case ExtraSettings.FastReplyTemplates:
                const catalogIdFieldName = `extraSettings[${index}].value.catalogId`

                return (
                    <ExtraSettingWrapper
                        title={t(`${tNamespace}fast-reply-templates`)}
                        onDelete={onDelete}
                        infoIconProps={{
                            id: "queueTooltipFastReplyTemplates",
                            content: t(`${tTooltipNamespace}.fast-reply-templates`)
                        }}
                    >
                        <QueueFastReplyTemplates fieldName={catalogIdFieldName} t={t} />
                    </ExtraSettingWrapper>
                )
            case ExtraSettings.AutoHolding:
                const settingTemplate = `extraSettings[${index}].value`

                return (
                    <ExtraSettingWrapper title={t(`${tNamespace}autoholding-title`)} onDelete={onDelete}>
                        <QueueAutoHolding settingTemplate={settingTemplate} t={t}></QueueAutoHolding>
                    </ExtraSettingWrapper>
                )
            case ExtraSettings.DialogGroups:
                const dialogGroupsName = `extraSettings[${index}].value.groups`

                return (
                    <ExtraSettingWrapper title={t(`${tNamespace}dialog-groups`)} onDelete={onDelete}>
                        <QueueDialogGroups fieldName={dialogGroupsName} t={t} />
                    </ExtraSettingWrapper>
                )
            case ExtraSettings.AllowCatalogs:
                return (
                    <ExtraSettingWrapper title={t(`${tNamespace}allow-catalogs`)} onDelete={onDelete}>
                        <QueueAllowCatalogs
                            templateName={`extraSettings[${index}].value.Allowed`}
                            values={setting.value.Allowed}
                        />
                    </ExtraSettingWrapper>
                )
            case ExtraSettings.DialogFinish:
                const finishDialogArticleName = `extraSettings[${index}].value.finishDialogArticle`

                return (
                    <ExtraSettingWrapper title={t(`${tNamespace}finish-dialog`)} onDelete={onDelete}>
                        <QueueDialogFinish fieldName={finishDialogArticleName} t={t} />
                    </ExtraSettingWrapper>
                )
        }
    }

    return renderContent()
}

export default QueueExtraSetting
