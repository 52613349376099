import React, { useContext } from "react"
import { Form } from "react-bootstrap"
import { Formik, FormikProps } from "formik"
import * as Yup from "yup"
import { useTranslation } from "react-i18next"
import "./GlobalUserForm.scss"
import { WithT } from "i18next"
import { preventSubmitOnEnter } from "../../utility/common/preventSubmitOnEnter"
import SettingsGroup from "../SettingsGroup/SettingsGroup"
import { shallowEqual, useDispatch, useSelector } from "react-redux"
import { updateGlobalUser } from "../../store/users/thunks"
import UserCard from "../UserCard/UserCard"
import { formTranslation } from "../../locales/form"
import { selectUpdateGlobalUser } from "../../store/users/selectors"
import { nameof, nameof2 } from "../../utility/common/nameof"
import { Dispatch } from "../../utility/common/storeHelper"
import ValidatableInput from "../ValidatableInput/ValidatableInput"
import { GlobalUser, UpdateGlobalUserRequest } from "../../models/globalUser"
import LoadingButton from "../LoadingButton/LoadingButton"
import ConfigContext from "../ConfigContext/ConfigContext"
import { handleSettingsLabelTranslation, SettingsLabelType } from "../../utility/common/handleSettingsLabelTranslation"
import { GlobalUserSurvey } from "../../models/globalUserSurvey"
import GlobalUserSurveyField from "../GlobalUserSurveyField/GlobalUserSurveyField"
import { NEW_WORKPLACE_ENABLED } from "../../utility/common/flags"
import useRefCheck from "../../utility/common/useRefCheck"
import { mapValuesToPermissions, ViewNewOperatorUI } from "../../permissions"
import { getSettings } from "../../utility/common/getSettings"
import { Settings } from "../../types/settings"

const tRolesNamespace = "roles:"

type GlobalUserSlotValues = {
    [slotId: string]: string
}

export type GlobalUserValues = {
    globalPermissions: Settings<string>
    maxCapacity: number
    QuestionaryFields: GlobalUserSlotValues
}

interface Props {
    user: GlobalUser
    allGlobalPermissions: string[]
    globalUserSurvey: GlobalUserSurvey
    onClose: () => void
}

const FormikGlobalUserForm: React.FC<Props & FormikProps<GlobalUserValues> & WithT> = props => {
    const { user, t, values, handleSubmit, globalUserSurvey, initialValues } = props

    const updateState = useSelector(selectUpdateGlobalUser, shallowEqual)
    const {
        WebConfig: {
            appSettings: { signUpEnabled }
        }
    } = useContext(ConfigContext)
    return (
        <Form className="global-user-form" onSubmit={handleSubmit} onKeyPress={preventSubmitOnEnter}>
            <div className="global-user-form__content">
                <div className="section">
                    <div className="user-info">
                        <div className="user-info__section">
                            <UserCard firstName={user.FirstName} lastName={user.LastName} picture={user.Picture} />
                        </div>
                        <div className="user-info__section">
                            <div className="user-info__name">{t(formTranslation.login)}</div>
                            <div className="user-info__value">{user.Login}</div>
                        </div>
                        <div className="user-info__section">
                            <div className="user-info__name">{t(formTranslation.firstName)}</div>
                            <div className="user-info__value">{user.FirstName}</div>
                        </div>
                        <div className="user-info__section">
                            <div className="user-info__name">{t(formTranslation.lastName)}</div>
                            <div className="user-info__value">{user.LastName}</div>
                        </div>

                        {globalUserSurvey.Fields.map(field => {
                            return (
                                <GlobalUserSurveyField
                                    key={field.Slot.SlotId}
                                    slot={field.Slot}
                                    slotValue={initialValues.QuestionaryFields[field.Slot.SlotId]}
                                    name={nameof2("QuestionaryFields", `${field.Slot.SlotId}`)}
                                    id={nameof2("QuestionaryFields", `${field.Slot.SlotId}`)}
                                />
                            )
                        })}
                    </div>
                </div>
                <div className="section">
                    <SettingsGroup<string>
                        name={nameof<GlobalUserValues>("globalPermissions")}
                        title={t(`${tRolesNamespace}permissions`)}
                        settingsValues={values.globalPermissions}
                        disabled={!signUpEnabled}
                        getLabel={v => handleSettingsLabelTranslation(tRolesNamespace, v, SettingsLabelType.Roles, t)}
                    />
                </div>
                <div className="section">
                    <ValidatableInput
                        id="formMaxCapacity"
                        type="number"
                        name={nameof<GlobalUserValues>("maxCapacity")}
                        label={t(formTranslation.maxConcurrentTasks)}
                    />
                </div>
            </div>
            <div className="global-user-form__footer">
                <LoadingButton type="submit" loading={updateState.inProcess} variant="primary" block>
                    {t(formTranslation.save)}
                </LoadingButton>
            </div>
        </Form>
    )
}

const getValuesFromUser = (
    user: GlobalUser,
    allGlobalPermissions: string[],
    globalUserSurvey: GlobalUserSurvey,
    isNewWorkplaceEnabled?: boolean
): GlobalUserValues => {
    const filteredGlobalPermissions = allGlobalPermissions.filter(permission => {
        if (permission === ViewNewOperatorUI && !isNewWorkplaceEnabled) {
            return false
        }

        return true
    })
    const enabledGlobalPermissions = user.GlobalPermissions.filter(p => p.IsEnabled).map(p => p.Name)

    const globalPermissionsSettings = getSettings(filteredGlobalPermissions, enabledGlobalPermissions)

    const filledFields = user.QuestionaryFields.reduce<{ [key: string]: string }>((acc, slot) => {
        const isSurveyFieldFilled = Boolean(
            globalUserSurvey.Fields.find(surveyField => surveyField.Slot.SlotId === slot.SlotId)
        )
        if (isSurveyFieldFilled) {
            acc[slot.SlotId] = slot.Value
        }
        return acc
    }, {})

    return {
        globalPermissions: globalPermissionsSettings,
        maxCapacity: user.MaxCapacity,
        QuestionaryFields: {
            ...filledFields
        }
    }
}

const buildRequest = (user: GlobalUser, values: GlobalUserValues): UpdateGlobalUserRequest => {
    return {
        Login: user.Login,
        GlobalPermissions: mapValuesToPermissions(values.globalPermissions),
        MaxCapacity: values.maxCapacity,
        QuestionaryFields: Object.entries(values.QuestionaryFields).map(([SlotId, Value]) => ({ SlotId, Value }))
    }
}

const GlobalUserForm: React.FC<Props> = props => {
    const { t } = useTranslation()
    const dispatch = useDispatch<Dispatch>()
    const { user, allGlobalPermissions, onClose, globalUserSurvey } = props
    const isNewWorkplaceEnabled = useRefCheck(NEW_WORKPLACE_ENABLED)

    return (
        <Formik
            enableReinitialize={true}
            initialValues={getValuesFromUser(user, allGlobalPermissions, globalUserSurvey, isNewWorkplaceEnabled)}
            validationSchema={() => {
                return Yup.object().shape({
                    maxCapacity: Yup.number()
                        .min(0, formTranslation.enterPositiveNumber)
                        .required(formTranslation.enterNumber)
                })
            }}
            onSubmit={async (values: GlobalUserValues) => {
                await dispatch(updateGlobalUser(buildRequest(user, values), onClose))
            }}
        >
            {formikProps => <FormikGlobalUserForm {...props} {...formikProps} t={t} />}
        </Formik>
    )
}

export default GlobalUserForm
