import React, { ReactNode, useContext, useState } from "react"
import cn from "classnames"
import { ClassProps } from "../../utility/common/props"
import styles from "./Article.module.scss"
import { ScrollUpButtonContext } from "../PageLayout/PageLayout"
import ArticleActionButtons from "../ArticleActionButtons/ArticleActionButtons"
import { useTranslation } from "react-i18next"
import ArticleFillSlotsForm from "../ArticleFillSlotsForm/ArticleFillSlotsForm"
import { FillingSlot } from "../../models/slot"
import { usePostUpdateSlotContextMutation } from "../../api/controllers/dialogs"
import useScroll from "../../hooks/useScroll"
import ArticleTitle from "../ArticleTitle/ArticleTitle"
import { Comparison } from "../../models/articleCompare"
import ScrollButton, { ScrollButtonDirection } from "../ScrollButton/ScrollButton"

export interface ArticleProps extends ClassProps {
    symbolCode: string
    headerTop?: ReactNode
    headerRight?: ReactNode
    prepended?: ReactNode
    title: ReactNode | string
    widgets?: ReactNode
    body?: ReactNode | string
    isExpanded: boolean
    testId?: string
    TitleComparison?: Comparison<string>[]
    withActions?: boolean
    isPublished: boolean
}

export interface SlotsToFillStateProps {
    selectedDialogId: string
    selectedClientId: string
    slotsToFill: FillingSlot[]
}

const tNamespace = "knowledgeBase:"

const Article: React.FC<ArticleProps> = props => {
    const {
        className,
        symbolCode,
        headerTop,
        prepended,
        headerRight,
        title,
        body,
        widgets,
        isExpanded,
        testId,
        TitleComparison,
        withActions,
        isPublished
    } = props

    const { isScrollUpButtonVisible, scrollableArea } = useContext(ScrollUpButtonContext)
    const { t } = useTranslation()
    const scrollToTargetRef = useScroll<HTMLDivElement>({ scrollableArea })

    const [slotsToFillState, setSlotsToFillState] = useState<SlotsToFillStateProps>({
        selectedDialogId: "",
        selectedClientId: "",
        slotsToFill: []
    })

    const [updateSlotContextRequest] = usePostUpdateSlotContextMutation()

    const handleSubmitFillingSlots = (filledSlots: Record<string, string>) => {
        const slotsToFill = Object.entries(filledSlots).map(([key, value]) => ({ Id: key, Value: value }))
        if (slotsToFillState.selectedDialogId) {
            updateSlotContextRequest({
                DialogId: slotsToFillState.selectedDialogId,
                UpdatedSlots: slotsToFill
            })
            setSlotsToFillState && setSlotsToFillState({ selectedDialogId: "", selectedClientId: "", slotsToFill: [] })
        } else if (slotsToFillState.selectedClientId) {
            setSlotsToFillState && setSlotsToFillState({ selectedDialogId: "", selectedClientId: "", slotsToFill })
        }
    }

    return (
        <div>
            <div className={cn(styles.article, className)} data-testid={testId}>
                {prepended && <div className={styles.article__prepended}>{prepended}</div>}
                {withActions ? (
                    <ArticleActionButtons
                        symbolCode={symbolCode}
                        isPublished={isPublished}
                        setSlotsToFillState={setSlotsToFillState}
                        slotsToFill={slotsToFillState.slotsToFill}
                    />
                ) : (
                    <ArticleTitle
                        isExpanded={isExpanded}
                        headerTop={headerTop}
                        title={title}
                        headerRight={headerRight}
                        titleComparison={TitleComparison}
                    />
                )}
                {slotsToFillState.slotsToFill.length > 0 &&
                    (slotsToFillState.selectedDialogId || slotsToFillState.selectedClientId) && (
                        <ArticleFillSlotsForm
                            slotsToFill={slotsToFillState.slotsToFill}
                            submit={handleSubmitFillingSlots}
                            alertingText={t(`${tNamespace}article-fill-slots.alert`)}
                        />
                    )}
                {widgets && (
                    <div className={cn(styles.article__parameters, isExpanded && styles.article_expanded)}>
                        {widgets}
                    </div>
                )}
                {body && <div className={cn(styles.article__body, isExpanded && styles.article_expanded)}>{body}</div>}
            </div>
            {isScrollUpButtonVisible && (
                <div className={cn(styles.scrollButton, isExpanded && styles.scrollButton_expanded)}>
                    <ScrollButton direction={ScrollButtonDirection.up} onClick={() => scrollToTargetRef()} />
                </div>
            )}
        </div>
    )
}

export default Article
