import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import {
    selectCreateQueueState,
    selectMonitoringOverviewCategoryId,
    selectQueueCategories
} from "../../store/queues/selectors"
import type { ItemId } from "@atlaskit/tree/types"
import { toggleQueueCategory } from "../../store/queues/slice"
import { TreeDestinationPosition, TreeItem, TreeSourcePosition } from "@atlaskit/tree"
import { KnowledgeBasePermittedAction } from "../../models/knowledgeBase/permission"
import { isModifyPermitted } from "../../utility/knowledgeBase/useModifyPermitted"
import QueueCatalogPopoverContainer from "../CatalogPopover/QueueCatalogPopoverContainer"
import { isQueueCategoryWithoutQueues } from "../../models/queueCategory"
import { faPlus } from "@fortawesome/pro-light-svg-icons/faPlus"
import CategoryAction from "../CategoryAction/CategoryAction"
import { useTranslation } from "react-i18next"
import { convertQueuePermissionToKnowledgeBasePermission } from "../../utility/queues/queueCategoryForm"
import usePermissionsCheck from "../../utility/common/usePermissionsCheck"
import { ModifyQueues } from "../../permissions"
import { testId } from "../../utility/tests/testId"
import { viewAllQueues, viewQueue, viewQueueCategory } from "../../store/queues/actions"
import { moveItem } from "../../utility/common/tree"
import { moveQueueToAnotherCategory } from "../../store/queues/thunks"
import { AutomaticProcessingCategoryId, QueueCategoryRootId } from "../../utility/queues/queueCategoriesTree"
import { faFileUser, faFolderMinus } from "@fortawesome/pro-light-svg-icons"
import LazyCatalog from "../Catalog/LazyCatalog"

const tNamespace = "queues:"

export interface QueueCategoriesProps {
    projectId: string
    onAddQueue: (categoryId: ItemId) => void
    onEditCatalogClick: () => void
    onCancelEditCatalogClick: () => void
    isSelectCatalogAllowed?: boolean
    onCategoryClick?: (id: string) => void
}

function isAutomaticProcessing(item: TreeItem) {
    return item.id === AutomaticProcessingCategoryId
}

const QueueCategories: React.FC<QueueCategoriesProps> = props => {
    const {
        onAddQueue,
        projectId,
        onEditCatalogClick,
        onCancelEditCatalogClick,
        onCategoryClick,
        isSelectCatalogAllowed = true
    } = props

    const tree = useSelector(selectQueueCategories)
    const apiState = useSelector(selectCreateQueueState)
    const monitoringOverviewCategoryId = useSelector(selectMonitoringOverviewCategoryId)
    const isModifyAllowed = usePermissionsCheck([ModifyQueues])
    const [editingId, setEditingId] = useState<string>()
    const [draggableId, setDraggableId] = useState<ItemId | undefined>(undefined)
    const dispatch = useDispatch()
    const { t } = useTranslation()

    const handleToggle = (itemId: ItemId, isExpanded: boolean) => {
        dispatch(
            toggleQueueCategory({
                ToggleCategoryId: itemId,
                IsExpanded: isExpanded
            })
        )
    }

    const handleDragEnabled = (item: TreeItem): boolean => {
        const isQueue = Boolean(item.data?.isQueue)
        return isModifyAllowed && isQueue && !isAutomaticProcessing(item)
    }

    useEffect(() => {
        if (editingId === undefined) {
            return
        }

        if (!editingId) {
            onCancelEditCatalogClick()
        } else {
            onEditCatalogClick()
        }
    }, [editingId, onCancelEditCatalogClick, onEditCatalogClick])

    const handleDragStart = (draggableId: ItemId) => {
        setDraggableId(draggableId)
    }

    const handleDragEnd = (source: TreeSourcePosition, destination?: TreeDestinationPosition) => {
        const itemId = draggableId
        setDraggableId(undefined)

        if (apiState.inProcess) {
            return
        }

        if (!tree) {
            return
        }

        if (!destination || !itemId) {
            return
        }

        if (source.parentId === destination.parentId) {
            return
        }

        const { newTree, draggableItem, sourceItem, destinationItem } = moveItem(itemId, tree, source, destination)

        const isQueue = Boolean(destinationItem.data?.isQueue)

        if (isQueue || destinationItem.id === QueueCategoryRootId) {
            return
        }

        dispatch(moveQueueToAnotherCategory(projectId, newTree, sourceItem, destinationItem, draggableItem))
    }

    const handleTitleClick = (categoryItem: TreeItem) => {
        const { data } = categoryItem
        if (data) {
            const { Id, parentId: ParentId } = data
            if (!Id) return
            if (ParentId) {
                if (onCategoryClick) {
                    onCategoryClick(Id)
                } else {
                    dispatch(viewQueue(ParentId, Id))
                }
            } else if (isSelectCatalogAllowed) {
                dispatch(viewQueueCategory(Id))
            }
        }
    }

    const handleRootItemTitleClick = () => {
        if (isSelectCatalogAllowed) {
            dispatch(viewAllQueues())
        }
    }

    const handleAddQueue = (categoryId: ItemId) => {
        onAddQueue(categoryId)
    }

    const handleCanEdit = (permittedAction?: KnowledgeBasePermittedAction) => {
        return isModifyAllowed && isModifyPermitted(permittedAction)
    }

    const handleIcon = (item: TreeItem) => {
        const isQueue = Boolean(item.data?.isQueue)
        if (isQueue) {
            return faFileUser
        }
        return faFolderMinus
    }

    const handleGetItemExtensions = (item: TreeItem) => {
        const { data } = item
        const isMonitoringOverview = !!(monitoringOverviewCategoryId && monitoringOverviewCategoryId === item.id)
        if (item.hasChildren && isQueueCategoryWithoutQueues(data)) {
            // category options
            const permissions = data.Access.map(convertQueuePermissionToKnowledgeBasePermission)
            const result = [
                <QueueCatalogPopoverContainer
                    key="edit-category"
                    projectId={projectId}
                    catalogId={String(item.id)}
                    catalogTitle={data.Name}
                    permissions={permissions}
                    permissionsWithNested={false}
                    isRemoveUnavailable={item.children.length > 0}
                    isRemoveDisabled={isMonitoringOverview}
                    onEditClick={id => setEditingId(prevId => (prevId === id ? "" : id))}
                    onCancelEditClick={() => setEditingId("")}
                    isEdit
                />
            ]
            if (isMonitoringOverview) {
                return result
            }
            return [
                ...result,
                <CategoryAction
                    id="add-queue"
                    key="add-queue"
                    tooltipText={t(`${tNamespace}add-queue`)}
                    icon={faPlus}
                    onClick={() => handleAddQueue(item.id)}
                    loading={item.isChildrenLoading || apiState.inProcess}
                    testId={testId.addQueue}
                />
            ]
        } else {
            // queue options
            return []
        }
    }

    const handleGetExtensions = () => [
        <QueueCatalogPopoverContainer key="queue-catalog-menu" projectId={projectId} isEdit={false} />
    ]

    if (!tree) {
        return null
    }

    return (
        <LazyCatalog
            tree={tree}
            editingId={editingId}
            extensions={handleGetExtensions()}
            onToggle={handleToggle}
            onRootItemTitleClick={handleRootItemTitleClick}
            onTitleClick={handleTitleClick}
            onCanEdit={handleCanEdit}
            onGetItemExtensions={handleGetItemExtensions}
            isDragEnabled={handleDragEnabled}
            onDragStart={handleDragStart}
            onDragEnd={handleDragEnd}
            categoryIcon={handleIcon}
        />
    )
}

export default QueueCategories
