import React, { ChangeEvent, FocusEvent, forwardRef, useCallback, useEffect, useState } from "react"
import styles from "./SearchInput.module.scss"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSearch, faTimes } from "@fortawesome/pro-light-svg-icons"
import { useTranslation } from "react-i18next"
import cn from "classnames"
import Spinner from "../Spinner/Spinner"
import { formTranslation } from "../../locales/form"
import { FormControl } from "react-bootstrap"
import { testId } from "../../utility/tests/testId"
import debounce from "lodash-es/debounce"

export interface SearchInputProps {
    name?: string
    value?: string
    onChange?: (searchValue: string) => void
    onFocus?: (searchValue: string) => void
    onBlur?: (searchValue: string) => void
    onClear?: () => void
    disabled?: boolean
    label?: string
    isLoading?: boolean
    className?: string
}

const SearchFormInput = forwardRef<HTMLDivElement, SearchInputProps>((props, ref) => {
    const { t } = useTranslation()
    const { label, className, disabled, isLoading = false, onClear, onChange, onBlur, onFocus, name, value } = props
    const [searchValue, setSearchValue] = useState(value)

    useEffect(() => {
        setSearchValue(value)
    }, [value])

    const handleClear = () => {
        if (onClear) {
            onClear()
        }

        setSearchValue("")
    }

    const handleChangeCallback = useCallback(
        debounce((searchValue: string) => {
            if (!onChange) {
                return
            }

            onChange(searchValue)
        }, 300),
        []
    )

    const handleFocus = (e: FocusEvent<HTMLInputElement>) => {
        if (!onFocus) {
            return
        }

        onFocus(e.target.value)
    }

    const handleBlur = (e: FocusEvent<HTMLInputElement>) => {
        if (!onBlur) {
            return
        }

        onBlur(e.target.value)
    }

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        handleChangeCallback(e.target.value)
        setSearchValue(e.target.value)
    }

    return (
        <div className={cn(styles.wrap, className)} ref={ref}>
            <div className={cn(styles.left)}>
                <FontAwesomeIcon icon={faSearch} className={cn(styles.left__icon)} />
                {label && <span>{label}</span>}
            </div>
            <FormControl
                name={name}
                onChange={handleChange}
                onFocus={handleFocus}
                onBlur={handleBlur}
                value={searchValue}
                className={cn(styles.input, label && styles.input_withLabel)}
                placeholder={t(formTranslation.search)}
                disabled={disabled}
                data-test-id={testId.searchInput}
                autoComplete="off"
            />
            <div
                className={cn(styles.right, isLoading || styles.right_clear)}
                onClick={isLoading ? undefined : handleClear}
            >
                {isLoading ? (
                    <Spinner className={styles.right__loading} />
                ) : (
                    <FontAwesomeIcon icon={faTimes} className={styles.right__icon} />
                )}
            </div>
        </div>
    )
})

export default SearchFormInput
