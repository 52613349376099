import { mutateTree, TreeData, TreeItem } from "@atlaskit/tree"
import { QueueCategory } from "../../models/queueCategory"
import { Queue, QueueUpdatedMsg } from "../../models/queue"
import { ItemId } from "@atlaskit/tree/types"
import { formTranslation } from "../../locales/form"
import i18n from "../../i18n"
import { KnowledgeBasePermittedAction } from "../../models/knowledgeBase/permission"

export const QueueCategoryRootId = "all"
export const AutomaticProcessingCategoryId = "auto-processing"
export const AutomaticProcessingCategoryTitleKey = "queues:dialogs-on-automatic-processing.queue-name"

export const convertQueueToTreeItem = (queue: Queue, categoryId: string): TreeItem => ({
    id: queue.Id,
    children: [],
    hasChildren: false,
    isExpanded: false,
    isChildrenLoading: false,
    data: {
        Id: queue.Id,
        title: queue.Name,
        parentId: categoryId,
        isQueue: true
    }
})

export const convertQueuesToTreeItemsMap = (queues: Queue[], categoryId: string): Record<ItemId, TreeItem> => {
    return queues.reduce<Record<ItemId, TreeItem>>(
        (acc, queue) => ({
            ...acc,
            [queue.Id]: convertQueueToTreeItem(queue, categoryId)
        }),
        {}
    )
}

export const getAutomaticProcessingQueue = (parentCategoryId: string): Record<ItemId, TreeItem> => {
    const item = {
        id: AutomaticProcessingCategoryId,
        children: [],
        hasChildren: false,
        isExpanded: false,
        isChildrenLoading: false,
        data: {
            Id: AutomaticProcessingCategoryId,
            title: i18n.t(AutomaticProcessingCategoryTitleKey),
            parentId: parentCategoryId,
            isQueue: true
        }
    }
    return {
        [AutomaticProcessingCategoryId]: item
    }
}

type ConvertQueueCategoryReturn = {
    map: Record<string, TreeItem>
    id: string
}

export const convertQueueCategoryToTree = (
    queueCategory: QueueCategory,
    isAutomaticProcessing: boolean,
    isExpanded = false
): ConvertQueueCategoryReturn => {
    const { Queues, ...data } = queueCategory
    const items = isAutomaticProcessing
        ? getAutomaticProcessingQueue(queueCategory.Id)
        : convertQueuesToTreeItemsMap(Queues, queueCategory.Id)

    const categoryItem: TreeItem = {
        id: queueCategory.Id,
        children: Object.keys(items),
        hasChildren: true,
        isExpanded,
        isChildrenLoading: false,
        data: {
            title: queueCategory.Name,
            permittedAction: KnowledgeBasePermittedAction.Edit,
            ...data
        }
    }

    return {
        map: {
            [categoryItem.id]: categoryItem,
            ...items
        },
        id: queueCategory.Id
    }
}

type GetQueueCategoriesMap = {
    resultMap: Record<string, TreeItem>
    ids: string[]
}

export const convertQueueCategoriesToTree = (
    queueCategories: QueueCategory[],
    automaticProcessingCategoryId?: string
): TreeData => {
    const { ids, resultMap } = queueCategories.reduce<GetQueueCategoriesMap>(
        (acc, category) => {
            const isAutomaticProcessing = !!(
                automaticProcessingCategoryId && category.Id === automaticProcessingCategoryId
            )
            const { id, map } = convertQueueCategoryToTree(category, isAutomaticProcessing)
            return {
                resultMap: {
                    ...acc.resultMap,
                    ...map
                },
                ids: [...acc.ids, id]
            }
        },
        {
            resultMap: {},
            ids: []
        }
    )

    const rootItem: TreeItem = {
        id: QueueCategoryRootId,
        children: ids,
        hasChildren: true,
        isExpanded: true,
        isChildrenLoading: false,
        data: {
            title: i18n.t(formTranslation.all),
            permittedAction: KnowledgeBasePermittedAction.Edit
        }
    }

    return {
        rootId: QueueCategoryRootId,
        items: {
            [QueueCategoryRootId]: rootItem,
            ...resultMap
        }
    }
}

export const addQueueCategory = (queueCategory: QueueCategory, tree?: TreeData) => {
    if (!tree) {
        return convertQueueCategoriesToTree([queueCategory], undefined)
    }

    const { id, map } = convertQueueCategoryToTree(queueCategory, false)
    const rootItem = tree.items[tree.rootId]

    const newChildren = rootItem.children.includes(id) ? rootItem.children : [...rootItem.children, id]

    const newTree: TreeData = {
        ...tree,
        items: {
            ...tree.items,
            ...map
        }
    }

    return mutateTree(newTree, tree.rootId, { children: newChildren, isChildrenLoading: false })
}

export const updateQueueCategory = (queueCategory: QueueCategory, tree: TreeData) => {
    const { Queues, ...data } = queueCategory
    return mutateTree(tree, queueCategory.Id, {
        data: { title: queueCategory.Name, permittedAction: KnowledgeBasePermittedAction.Edit, ...data }
    })
}

export const removeQueueCategory = (categoryId: string, tree: TreeData) => {
    if (categoryId === tree.rootId) {
        return
    }
    const rootItem = tree.items[tree.rootId]
    const newChildren = rootItem.children.filter(c => c !== categoryId)
    const newTree = {
        ...tree,
        items: {
            ...tree.items
        }
    }
    delete newTree.items[categoryId]
    return mutateTree(newTree, tree.rootId, { children: newChildren, isChildrenLoading: false })
}

export const addOrUpdateQueue = (queueCategoryId: string, queue: Queue, tree: TreeData) => {
    const queueItem = convertQueueToTreeItem(queue, queueCategoryId)
    const categoryItem = tree.items[queueCategoryId]
    const newChildren = categoryItem.children.includes(queueItem.id)
        ? categoryItem.children
        : [...categoryItem.children, queueItem.id]
    const newTree: TreeData = {
        ...tree,
        items: {
            ...tree.items,
            [queueItem.id]: queueItem
        }
    }

    return mutateTree(newTree, queueCategoryId, { children: newChildren, isChildrenLoading: false })
}

export const updateQueueData = (queueUpdateMsg: QueueUpdatedMsg, tree: TreeData) => {
    const queueItem = tree.items[queueUpdateMsg.QueueId]
    if (queueItem) {
        return mutateTree(tree, queueUpdateMsg.QueueId, { data: { ...queueItem.data, title: queueUpdateMsg.Name } })
    } else {
        return tree
    }
}

export const updateCategoriesAfterMoving = (
    tree: TreeData,
    sourceCategory: QueueCategory,
    destinationCategory?: QueueCategory
): TreeData => {
    let destinationMap = null
    if (destinationCategory) {
        destinationMap = convertQueueCategoryToTree(destinationCategory, false, true)
    }
    const sourceMap = convertQueueCategoryToTree(sourceCategory, false, true)
    return {
        ...tree,
        items: {
            ...tree.items,
            ...(destinationMap ? destinationMap.map : {}),
            ...sourceMap.map
        }
    }
}

export const deleteQueue = (queueCategoryId: string, queueId: string, tree: TreeData) => {
    const newTree = { ...tree, items: { ...tree.items } }
    delete newTree.items[queueId]
    const categoryItem = tree.items[queueCategoryId]
    const newChildren = categoryItem.children.filter(c => c !== queueId)
    return mutateTree(newTree, queueCategoryId, { children: newChildren })
}
