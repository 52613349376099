import React, { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import {
    selectFavoriteArticlesBlock,
    selectFavoriteArticlesBlockExpanded,
    selectMoveFavoriteArticle
} from "../../store/knowledgeBase/selectors"
import Async from "../Async/Async"
import CatalogsLoader from "../Catalogs/CatalogsLoader"
import ErrorMessage from "../ErrorMessage/ErrorMessage"
import ErrorBoundary from "../ErrorBoundary/ErrorBoundary"
import { TreeData, TreeDestinationPosition, TreeItem, TreeSourcePosition } from "@atlaskit/tree"
import Catalog from "../Catalog/Catalog"
import { ArticleAction, ArticleKind } from "../../models/article"
import { ItemId } from "@atlaskit/tree/types"
import { expandFavoriteArticlesBlock, moveArticleInFavoriteBlock } from "../../store/knowledgeBase/thunks"
import { useTranslation } from "react-i18next"
import { buildArticleActionUrl } from "../../utility/knowledgeBase/articleUrlHelper"
import { push } from "connected-react-router"
import { moveItem } from "../../utility/common/tree"
import { mutateCurrentBranch, updateArticleFavoriteBlockTree } from "../../store/knowledgeBase/slice"
import { FavoriteArticleInfo } from "../../models/articleFavoriteBlock"
import CategoryAction from "../CategoryAction/CategoryAction"

export interface ArticlesFavoriteBlockProps {
    projectId?: string
    url: string
    onOpenArticle?: (code: string) => void
    onClickCategoryTitle?: (code: string) => void
    showContentOnHover?: boolean
    excludeKind?: ArticleKind
    isDragEnabled?: boolean
}
const tNamespace = "knowledgeBase:favorite-articles"

const ArticlesFavoriteBlock: React.FC<ArticlesFavoriteBlockProps> = props => {
    const {
        projectId,
        url,
        onOpenArticle,
        onClickCategoryTitle,
        showContentOnHover,
        excludeKind,
        isDragEnabled = true
    } = props
    const dispatch = useDispatch()
    const { t } = useTranslation()
    const favoriteArticlesBlockState = useSelector(selectFavoriteArticlesBlock)
    const isFavoriteArticlesBlockExpanded = useSelector(selectFavoriteArticlesBlockExpanded)
    const [draggableId, setDraggableId] = useState<ItemId | undefined>(undefined)
    const moveFavoriteArticle = useSelector(selectMoveFavoriteArticle)
    const blockId = favoriteArticlesBlockState.data?.rootId

    const getArticleInfoFromTree = (
        tree: TreeData,
        source: TreeSourcePosition,
        destination: TreeDestinationPosition
    ) => {
        const articlesInfo = Object.values(tree.items).reduce<FavoriteArticleInfo[]>((res, info) => {
            if (info.data.catalogCode !== tree.rootId) {
                res.push({
                    code: info.data.catalogCode,
                    title: info.data.title,
                    categoryCode: info.data.categoryCode,
                    isScenario: info.data.isScenario
                })
            }
            return res
        }, [])

        const sourceIndex = source?.index
        const destinationIndex = destination?.index

        if (sourceIndex !== undefined && destinationIndex !== undefined) {
            const [removedItem] = articlesInfo.splice(sourceIndex, 1)
            articlesInfo.splice(destinationIndex, 0, removedItem)
        }

        return articlesInfo
    }

    const handleDragStart = (draggableId: ItemId) => {
        setDraggableId(draggableId)
    }

    const handleDragEnd = (source: TreeSourcePosition, destination?: TreeDestinationPosition) => {
        const itemId = draggableId

        setDraggableId(undefined)

        if (moveFavoriteArticle.inProcess) {
            return
        }

        if (!destination || !itemId) {
            return
        }

        if (source.parentId !== destination?.parentId) {
            return
        }

        const tree = favoriteArticlesBlockState.data
        const { newTree } = moveItem(itemId, tree!, source, destination)

        dispatch(updateArticleFavoriteBlockTree(newTree))

        const movedArticleInfo = getArticleInfoFromTree(newTree, source, destination)
        projectId && dispatch(moveArticleInFavoriteBlock(projectId, movedArticleInfo))
    }

    const handleDragEnabled = () => {
        return !favoriteArticlesBlockState.inProcess && !moveFavoriteArticle.inProcess
    }

    const handleExpandFavoriteBlock = (isExpand: boolean) => {
        projectId && dispatch(expandFavoriteArticlesBlock(projectId, isExpand))
    }

    const handleArticleTitleClick = (item: TreeItem, catalogCode?: string, categoryCode?: string) => {
        if (onClickCategoryTitle && catalogCode) {
            onClickCategoryTitle(catalogCode)
            return
        }

        if (onOpenArticle && catalogCode) {
            onOpenArticle(catalogCode)
        }

        if (catalogCode) {
            const newUrl = buildArticleActionUrl(url, ArticleAction.View, catalogCode)

            dispatch(push(newUrl))

            item.id &&
                dispatch(
                    mutateCurrentBranch({
                        CurrentCategoryId: item.data.categoryCode,
                        CurrentCatalogCode: catalogCode
                    })
                )
        }
    }

    const getAddCategoryActionLoader = () => (
        <CategoryAction
            id={blockId as string}
            key={`${blockId}-favorite-article-loader`}
            loading={moveFavoriteArticle.inProcess}
        />
    )
    const handleGetItemExtensions = (item: TreeItem) => [getAddCategoryActionLoader()]

    return (
        <Async
            dataState={favoriteArticlesBlockState}
            processView={<CatalogsLoader itemsCount={3} />}
            errorView={({ message }) => <ErrorMessage text={message} />}
        >
            {({ data: favoriteArticlesBlock }) => {
                favoriteArticlesBlock.items[favoriteArticlesBlock.rootId].data.title = t(`${tNamespace}.title`)
                favoriteArticlesBlock.items[favoriteArticlesBlock.rootId].isExpanded = isFavoriteArticlesBlockExpanded

                return (
                    <ErrorBoundary local>
                        {favoriteArticlesBlock.rootId && Object.keys(favoriteArticlesBlock.items).length > 1 && (
                            <Catalog
                                tree={favoriteArticlesBlock}
                                onTitleClick={handleArticleTitleClick}
                                onCanEdit={() => true}
                                onToggle={() => handleExpandFavoriteBlock(isFavoriteArticlesBlockExpanded)}
                                onDragStart={handleDragStart}
                                onDragEnd={handleDragEnd}
                                isDragEnabled={isDragEnabled && handleDragEnabled}
                                isNestingEnabled={false}
                                extensions={[getAddCategoryActionLoader()]}
                                onGetItemExtensions={handleGetItemExtensions}
                                showContentOnHover={showContentOnHover}
                                excludeKind={excludeKind}
                            />
                        )}
                    </ErrorBoundary>
                )
            }}
        </Async>
    )
}

export default ArticlesFavoriteBlock
