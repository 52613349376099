import { Agent, JSAgentDeclaration, JSAgentSettingsTypes, JSAgentValues } from "../../models/agent"
import { Time } from "../common/time"

export const defaultJSAgentValues = {
    Id: "",
    Timeout: 10,
    Code: "",
    AIAgent: "aiassist2",
    Settings: "",
    FailOverAgent: "",
    Declaration: []
}

export const getValuesFromJSAgent = (agent: Agent): JSAgentValues => {
    const settings = JSON.parse(agent.Params["agent-settings"])

    return {
        Id: agent.Id,
        Timeout: Time.msToSeconds(agent.Params["agent-timeout"]),
        Code: agent.Params["agent-code"],
        AIAgent: agent.Params["aiassist-agent-id"],
        FailOverAgent: agent.FailOverAgent,
        Settings: agent.Params["agent-settings"],
        Declaration:
            settings.__declaration__?.map((declaration: JSAgentDeclaration) => ({
                ...declaration,
                value:
                    declaration.type === JSAgentSettingsTypes.Object
                        ? JSON.stringify(settings[declaration.id], undefined, 4)
                        : settings[declaration.id]
            })) ?? []
    }
}
