import React from "react"
import { useTranslation } from "react-i18next"
import styles from "./SettingsGroupContent.module.scss"
import { Button } from "react-bootstrap"
import { testId } from "../../utility/tests/testId"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheck, faSquare, faTrash } from "@fortawesome/pro-light-svg-icons"
import { formTranslation } from "../../locales/form"
import CheckBoxValidatableInput from "../CheckBoxValidatableInput/CheckBoxValidatableInput"
import { Settings } from "../../types/settings"
import AddButton from "../AddButton/AddButton"
import cn from "classnames"
import usePermissionHelpers from "../../hooks/usePermissionHelpers"
import { useFormikContext } from "formik"

const tNamespace = "roles:"

export interface SettingsGroupContentProps<T> {
    settingsValues: Settings<T>
    getLabel: (value: T) => string
    disabled?: boolean
    name: string
    isInRole?: boolean
    permHelpers?: ReturnType<typeof usePermissionHelpers>
    openSidebar?: () => void
}

type SettingsGroup<T> = {
    [key: string]: {
        value: T
        isChecked: boolean
    }
}

interface FormValues {
    [groupName: string]: SettingsGroup<string>
}

function SettingsGroupContent<T>(props: SettingsGroupContentProps<T>) {
    const { settingsValues, getLabel, isInRole = false, disabled, name, permHelpers, openSidebar } = props

    const { t } = useTranslation()

    const { setValues } = useFormikContext()

    const keys = Object.keys(settingsValues)
    const isEmptySettingsGroup = keys.length === 0

    const handleToggleAll = (enable: boolean) => (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.stopPropagation()
        const updates: Settings<string> = keys.reduce(
            (acc, key) => ({
                ...acc,
                [key]: {
                    value: settingsValues[key].value,
                    isChecked: enable
                }
            }),
            {}
        )
        setValues((prev: FormValues) => ({ ...prev, [name]: updates }))
    }

    return (
        <>
            {!isEmptySettingsGroup && (
                <div className={styles.settingsGroupContent__buttons}>
                    <Button
                        variant="light"
                        onClick={handleToggleAll(true)}
                        className={styles.settingsGroupContent__btn}
                        data-testid={testId.enableAll}
                        disabled={disabled}
                    >
                        <FontAwesomeIcon icon={faCheck} className={styles.settingsGroupContent__btnIcon} />
                        {t(formTranslation.enableAll)}
                    </Button>
                    <Button
                        variant="light"
                        onClick={handleToggleAll(false)}
                        className={styles.settingsGroupContent__btn}
                        data-testid={testId.turnOffAll}
                        disabled={disabled}
                    >
                        <FontAwesomeIcon icon={faSquare} className={styles.settingsGroupContent__btnIcon} />
                        {t(formTranslation.turnOffAll)}
                    </Button>
                    {isInRole && (
                        <Button
                            variant="light"
                            onClick={permHelpers?.handleDeleteAll}
                            className={styles.settingsGroupContent__btn}
                            data-testid={testId.deleteAllRights}
                            disabled={disabled}
                        >
                            <FontAwesomeIcon icon={faTrash} className={styles.settingsGroupContent__btnIcon} />
                            {t(`${tNamespace}delete-all-permissions`)}
                        </Button>
                    )}
                </div>
            )}
            <div
                className={cn(
                    styles.settingsGroupContent__inputsContainer,
                    isEmptySettingsGroup && styles.settingsGroupContent__inputsContainer_empty
                )}
            >
                {isInRole ? (
                    <>
                        {Object.entries(settingsValues).map(([k, v]) => (
                            <div className={styles.settingsGroupContent__checkBoxInput} key={k}>
                                <CheckBoxValidatableInput
                                    id={"form" + k}
                                    disabled={disabled}
                                    name={`${name}.${k}.isChecked`}
                                    label={getLabel(v.value)}
                                />
                                <FontAwesomeIcon
                                    icon={faTrash}
                                    onClick={() => permHelpers?.handleDeletePermission(k)}
                                    className={styles.settingsGroupContent__deletePermissionBtn}
                                />
                            </div>
                        ))}
                        {openSidebar && (
                            <AddButton
                                variant="primary"
                                className={styles.settingsGroupContent__addPermissionBtn}
                                text={t(`${tNamespace}add-permission`)}
                                onClick={openSidebar}
                                testId={testId.addPermission}
                            />
                        )}
                    </>
                ) : (
                    <>
                        {Object.entries(settingsValues).map(([k, v]) => (
                            <div className={styles.settingsGroupContent__checkBoxInput} key={k}>
                                <CheckBoxValidatableInput
                                    id={"form" + k}
                                    disabled={disabled}
                                    name={`${name}.${k}.isChecked`}
                                    label={getLabel(v.value)}
                                />
                            </div>
                        ))}
                    </>
                )}
            </div>
        </>
    )
}

export default SettingsGroupContent
