import React, { memo, useEffect } from "react"
import "./ProjectSidebar.scss"
import MenuItemComponent from "../MenuItem/MenuItem"
import { MenuItem } from "../../models/menu"
import Sidebar from "../Sidebar/Sidebar"
import { useSelector } from "react-redux"
import { selectCurrentProject } from "../../store/projects/selectors"
import { selectCurrentMainMenu } from "../../store/menu/selectors"
import { selectCurrentUser } from "../../store/users/selectors"
import { useTranslation } from "react-i18next"
import MenuItemRest from "../MenuItemRest/MenuItemRest"
import UpdateAppButton from "../UpdateAppButton/UpdateAppButton"

const tNamespace = "project:"

export interface ProjectSidebarProps {
    dialogMenu?: MenuItem
    userProfile?: MenuItem
    mainMenuItems: MenuItem[]
    menuItemsRest: MenuItem[]
    onToggleSidebar?: () => void
    selectMenu: (menuItemId: string) => void
}

const ProjectSidebar: React.FC<ProjectSidebarProps> = props => {
    const { mainMenuItems, menuItemsRest, onToggleSidebar, selectMenu, dialogMenu, userProfile } = props
    const { t, i18n } = useTranslation()
    const selectedMainMenu = useSelector(selectCurrentMainMenu)
    const user = useSelector(selectCurrentUser)
    const selectedProject = useSelector(selectCurrentProject)

    const getSelectedMenu = (menuId: string) => (selectedMainMenu ? selectedMainMenu === menuId : false)

    useEffect(() => {
        const handleChangeMenuMessage = (e: MessageEvent) => {
            const menuItem = e.data.menuItem

            if (menuItem) {
                selectMenu(menuItem)
            }
        }

        window.addEventListener("message", handleChangeMenuMessage)

        return () => window.removeEventListener("message", handleChangeMenuMessage)
    }, [selectMenu])

    if (!user) return null

    return (
        <Sidebar
            className="project-sidebar"
            header={
                <>
                    {dialogMenu && (
                        <MenuItemComponent
                            id={dialogMenu.id}
                            title={t(`${tNamespace}${dialogMenu.title}`)}
                            isSelected={getSelectedMenu(dialogMenu.id)}
                            onClick={() => selectMenu(dialogMenu.id)}
                            testId={dialogMenu.id}
                        />
                    )}
                </>
            }
            content={
                <>
                    {selectedProject &&
                        mainMenuItems.map(menuItem => (
                            <MenuItemComponent
                                id={menuItem.id}
                                key={menuItem.id}
                                title={
                                    i18n.exists(`${tNamespace}${menuItem.title}`)
                                        ? t(`${tNamespace}${menuItem.title}`)
                                        : menuItem.title
                                }
                                isSelected={getSelectedMenu(menuItem.id)}
                                onClick={() => selectMenu(menuItem.id)}
                                testId={menuItem.id}
                                icon={menuItem.icon}
                            />
                        ))}
                    <MenuItemRest
                        onClick={selectMenu}
                        getSelectedMenu={getSelectedMenu}
                        items={selectedProject ? menuItemsRest : []}
                        className="project-sidebar__rest"
                        onToggleSidebar={onToggleSidebar}
                    />
                </>
            }
            footer={
                <>
                    <UpdateAppButton t={t} />
                </>
            }
        />
    )
}

export default memo(ProjectSidebar)
