import { NewslineItem, NewslineSettings } from "../../../models/knowledgeBase/newsline"
import { UPDATE_ARTICLE_NEWSLINE_SETTINGS_FAILED_MESSAGE } from "../../../store/knowledgeBase/constants"
import { selectCurrentArticle } from "../../../store/knowledgeBase/selectors"
import { actions } from "../../../store/knowledgeBase/slice"
import store from "../../../store/store"
import { cacher } from "../../../utility/common/rtkQueryCacheUtils"
import { queryApi } from "../../api"
import { newslineTag } from "../../tags"
import { handleQueryApiError } from "../dialogs.helpers"

export const newslineApi = queryApi.injectEndpoints({
    endpoints(build) {
        return {
            getNewsline: build.query<NewslineItem[], string>({
                query: projectId => ({
                    url: `${projectId}/newsline`,
                    method: "GET"
                }),
                providesTags(result, error, projectId) {
                    return cacher.cacheByIdArg(newslineTag)(result, error, projectId)
                }
            }),
            updateArticleSettings: build.mutation<
                void,
                { projectId: string; symbolCode: string; articleId: string; settings: NewslineSettings }
            >({
                query: ({ projectId, symbolCode, articleId, settings }) => ({
                    url: `${projectId}/newsline/${symbolCode}/${articleId}`,
                    method: "PUT",
                    body: {
                        ...settings,
                        ArticleId: articleId
                    }
                }),

                async onQueryStarted({ settings }, { dispatch, queryFulfilled }) {
                    const article = selectCurrentArticle(store.getState())

                    try {
                        if (article && article.Article) {
                            await queryFulfilled.catch()
                            dispatch(
                                actions.getArticleSuccess({
                                    ...article,
                                    Article: {
                                        ...article.Article,
                                        NewslineSettigsDTO: settings
                                    }
                                })
                            )
                        } else {
                            throw new Error("No article found")
                        }
                    } catch (e) {
                        handleQueryApiError(dispatch, UPDATE_ARTICLE_NEWSLINE_SETTINGS_FAILED_MESSAGE, e)
                    }
                }
            })
        }
    }
})

export const { useGetNewslineQuery, useUpdateArticleSettingsMutation } = newslineApi
